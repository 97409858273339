import React, { Fragment, useState } from "react";
import { useSelector } from "react-redux";
import { selectMenuStates } from "../../../store/reducers/menuSlice";
import { useNavigate } from "react-router";
import { MiningEnergy } from "../../../models/miningenergy.model";
import { getEnergyCost } from "../../../api/getMiningMachines";
import { MiningMachine } from "../../../models/miningmachine.model";
import { EnergyIcon } from "../../../styles/assets/svgs/EnergyIcon";
import ModifyEnergyCostModal from "./modals/ModifyMiningMachineModal";
import RemoveEnergyCostModal from "./modals/RemoveEnergyCostModal";
type EnergyCostProps = {
    miningMachine: MiningMachine | undefined;
}

export default function EnergyCosts ({ miningMachine } : EnergyCostProps ) {

    const menu = useSelector(selectMenuStates)

    const navigate = useNavigate();
    
    const [records, setRecords] = React.useState<MiningEnergy[]>([]);

    const [showModal, setShowModal] = React.useState<boolean>(false);
    const [showModifyModal, setShowModifyModal] = React.useState<boolean>(false);
    const [showRemoveModal, setShowRemoveModal] = React.useState<boolean>(false);
    const [selectedRecord, setSelectedRecord] = React.useState<MiningEnergy>();

    const [recordStateChange, setRecordStateChange] = React.useState(false);
    
    const handleStateChange = () => setRecordStateChange(!recordStateChange);

    React.useEffect(() => {
        if(!miningMachine) return;

        getEnergyCost(miningMachine?.id)
        .then((response) => {
            setRecords(response);
        })
        .catch((error) => {
            console.error(error);
        });
        
    }, [recordStateChange, miningMachine])

    const closeModal = () => { 
        setShowRemoveModal(false) 
        setShowModifyModal(false); 
        setShowModal(false);
    }

    const openModal = () => {
        setShowModal(true);
    }

    const openRemoveModal = (record: MiningEnergy) => {
        setShowRemoveModal(true);
        setSelectedRecord(record);
    }

    const openModifyModal = (record: MiningEnergy) => {
        setShowModifyModal(true);
        setSelectedRecord(record);
    }

    return (
        <>
            <ModifyEnergyCostModal group_id={miningMachine?.group_id} title={"Energiaköltség módosítása"} showModal={showModifyModal} closeModal={closeModal} handleStateChange={handleStateChange} miningMachine={miningMachine} row={selectedRecord}/>
            <RemoveEnergyCostModal title={"Energiaköltség törlése"} showModal={showRemoveModal} closeModal={closeModal} handleStateChange={handleStateChange} row={selectedRecord}/>

            {records.length === 0 ? <p className="text-center">A(z) <span className="text-brown">{miningMachine?.name}</span> energiaköltségei jelenleg még nincsenek rögzítve!</p> : 
            <Fragment>
                <h2 className="text-lg">A(z) <span className="font-semibold">{miningMachine?.name}</span> energiaköltségei:</h2>
            
                <div className="flex justify-center border bg-dark-blue p-3 m-2 mx-auto">
                
                <table className="divide-y divide-gray-200 table-fixed divide-gray-600">
                
                    <thead className="bg-dark-blue text-cream">
                        <tr>
                            <th className="py-3 px-6 text-xs font-medium tracking-wider text-left uppercase text-cream">ID</th>
                            <th className="py-3 px-6 text-xs font-medium tracking-wider text-left uppercase text-cream">Dátum</th>
                            <th className="py-3 px-6 text-xs font-medium tracking-wider text-left uppercase text-cream">KW/h</th>
                            <th className="py-3 px-6 text-xs font-medium tracking-wider text-left uppercase text-cream">Ár</th>
                            <th className="py-3 px-6 text-xs font-medium tracking-wider text-left uppercase text-cream">Fizette</th>
                            <th className="py-3 px-6 text-xs text-center font-medium tracking-wider text-left uppercase text-cream" colSpan={2}>Műveletek</th>
                        </tr>
                    </thead>
                    <tbody className="divide-y bg-dark-blue divide-gray-600">
                    
                        {records.map((record, index) => (
                            <tr key={index} className="py-4 px-6 text-sm font-medium whitespace-nowrap text-white">
                                <td className="py-4 px-6 text-sm font-medium whitespace-nowrap text-white">
                                    {record.id}
                                </td>
                                <td className="py-4 px-6 text-sm font-medium whitespace-nowrap text-white">
                                    {record.date.toString().split('T')[0]}
                                </td>

                                <td className="py-4 px-6 text-sm font-medium whitespace-nowrap text-cream">
                                    {record.kwh}
                                </td>

                                <td className="py-4 px-6 text-sm font-medium whitespace-nowrap text-white">
                                    {record.price}
                                </td>

                                <td className="py-4 px-6 text-sm font-medium whitespace-nowrap text-white">
                                    {record.paidby ? 
                                    `Ügyfelek ${record.MiningEnergyPayer ? '(' + record.MiningEnergyPayer.user.name + ')' : '(Mindenki)'}` : 
                                    "Human D.C"}
                                </td>

                                <td onClick={() => openModifyModal(record)}>
                                    <p className="py-4 px-6 text-sm font-medium whitespace-nowrap text-cream hover:cursor-pointer hover:underline">Módosít</p>
                                </td>
                                <td onClick={() => openRemoveModal(record)}>
                                    <p className="py-4 px-6 text-sm font-medium whitespace-nowrap text-error hover:cursor-pointer hover:underline">Törlés</p>
                                </td>

                            </tr>
                        ))}
                    </tbody>
                </table>
                </div>
                </Fragment>
            }
        </>
    )
}