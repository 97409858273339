import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { DaisySummarize, UserEntity } from "../../../models/types/DaisySummarize";
import { Group } from "../../../models/group.model";
import { responseHero } from "../../../utils/axiosInterceptor";
import { DaisyClosure } from "../../../models/daisyclosure.model";

type ModifiedPayload = Pick<DaisyClosure, "group_id" | "user_id" | "invested_eur" | "share" | "invested_usd" | "compensations" | "wrongPayouts" | "capital_ratio">;

type ModalProps = {
    title: string;
    group: Group | undefined;
    showModal: boolean;
    closeModal: () => void;
    handleStateChange: () => void;
    record: UserEntity;
    daisyClosureRecord: DaisyClosure | undefined;
};

const EditDaisyExitDetailsModal = ({
    group,
    title,
    showModal,
    closeModal,
    handleStateChange,
    record,
    daisyClosureRecord
}: ModalProps) => {
    const [recordData, setRecordData] = useState<ModifiedPayload>({
        invested_eur: 0,
        invested_usd: 0,
        compensations: [],
        wrongPayouts: [],
        capital_ratio: 0,
        user_id: -1,
        group_id: -1,
        share: -1,
    });

    const [numberOfCompensations, setNumberOfCompensations] = useState(0);
    const [numberOfWrongPayouts, setNumberOfWrongPayouts] = useState(0);

    useEffect(() => {
        if (!record) return;

        if (!daisyClosureRecord) {
            setRecordData({
                invested_eur: record.invested.value_eur,
                invested_usd: record.invested.value_usd,
                compensations: [],
                wrongPayouts: [],
                capital_ratio: 0,
                user_id: record.metaData.user_id,
                group_id: record.metaData.group_id,
                share: record.share_amount
            });
        } else {
            setNumberOfCompensations(daisyClosureRecord.compensations.length);
            setNumberOfWrongPayouts(daisyClosureRecord.wrongPayouts.length);

            setTimeout(() => {
                setRecordData({
                    invested_eur: daisyClosureRecord.invested_eur,
                    invested_usd: daisyClosureRecord.invested_usd,
                    compensations: daisyClosureRecord.compensations.map((compensation) => ({
                        ...compensation,
                        date: compensation.date ? new Date(compensation.date) : new Date(), // Ensure valid date
                    })),
                    wrongPayouts: daisyClosureRecord.wrongPayouts.map((wrongPayout) => ({
                        ...wrongPayout,
                        date: wrongPayout.date ? new Date(wrongPayout.date) : new Date(), // Ensure valid date
                    })),
                    capital_ratio: daisyClosureRecord.capital_ratio,
                    user_id: daisyClosureRecord.user_id,
                    group_id: daisyClosureRecord.group_id,
                    share: daisyClosureRecord.share
                });
            }, 500)
        }
    }, [record, daisyClosureRecord]);

    useEffect(() => {
        setRecordData((prevData) => {
            const newCompensations = [...prevData.compensations];
            while (newCompensations.length < numberOfCompensations) {
                newCompensations.push({ date: new Date(), amount: 0 });
            }
            while (newCompensations.length > numberOfCompensations) {
                newCompensations.pop();
            }
            return { ...prevData, compensations: newCompensations };
        });
    }, [numberOfCompensations]);

    const handleChange = (
        type: keyof ModifiedPayload | "compensations",
        value: any,
        index?: number
    ) => {
        setRecordData((prevData) => {
            if (type === "compensations" && typeof index === "number") {
                const newCompensations = [...prevData.compensations];
                newCompensations[index] = {
                    ...newCompensations[index],
                    ...value,
                };
                return { ...prevData, compensations: newCompensations };
            }

            if (type === "wrongPayouts" && typeof index === "number") {
                const newWrongPayouts = [...prevData.wrongPayouts];
                newWrongPayouts[index] = {
                    ...newWrongPayouts[index],
                    ...value,
                };
                return { ...prevData, wrongPayouts: newWrongPayouts };
            }

            return {
                ...prevData,
                [type]: value,
            };
        });
    };

    const makeRecord = async () => {
        if (!group) return;

        try {
            await responseHero.post(
                `${process.env.REACT_APP_API_URL}/daisyclosure/${group.group_id}/${record.metaData.user_id}`,
                { record: recordData }
            );
            closeModal();
            handleStateChange();
            toast.success("Sikeres frissítés!");
        } catch (error: any) {
            console.error(JSON.stringify(error.response?.data?.message, null, 2));
            closeModal();
            toast.error("Sikertelen frissítés!");
        }
    };

    return (
        <>
            {showModal && (
                <div>
                    <div className="flex justify-center items-center overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
                        <div className="relative w-full my-6 mx-auto max-w-4xl">
                            <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                                <div className="flex items-start justify-between p-5 border-b border-solid border-gray-300 rounded-t">
                                    <h3 className="text-xl font-semibold">
                                        <span className="text-brown">DAISY</span> - {title}
                                    </h3>
                                </div>
                                <div className="relative p-6 flex-auto">
                                    <form>
                                        <div className="mb-4">
                                            <label className="block text-sm font-bold text-dark-blue">
                                                Tőkeviszonyszám
                                            </label>
                                            <input
                                                type="number"
                                                value={recordData.capital_ratio}
                                                onChange={(e) =>
                                                    handleChange("capital_ratio", parseFloat(e.target.value))
                                                }
                                                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700"
                                            />
                                        </div>
                                        <div className="mb-4">
                                            <label className="block text-sm font-bold text-dark-blue">
                                                Befizetett (USD)
                                            </label>
                                            <input
                                                type="number"
                                                value={recordData.invested_usd}
                                                onChange={(e) =>
                                                    handleChange("invested_usd", parseFloat(e.target.value))
                                                }
                                                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700"
                                            />
                                        </div>
                                        <div className="mb-4">
                                            <label className="block text-sm font-bold text-dark-blue">
                                                Befizetett (EUR)
                                            </label>
                                            <input
                                                type="number"
                                                value={recordData.invested_eur}
                                                onChange={(e) =>
                                                    handleChange("invested_eur", parseFloat(e.target.value))
                                                }
                                                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700"
                                            />
                                        </div>
                                        <div className="mb-4">
                                            <label className="block text-sm font-bold text-dark-blue">
                                                Részvények darabszáma (db)
                                            </label>
                                            <input
                                                type="number"
                                                value={recordData.share}
                                                onChange={(e) =>
                                                    handleChange("share", parseFloat(e.target.value))
                                                }
                                                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700"
                                            />
                                        </div>
                                        <div className="mb-4">
                                            <label className="block text-sm font-bold text-dark-blue">
                                                HUMAN D.C. Kompenzációk száma
                                            </label>
                                            <select
                                                value={numberOfCompensations}
                                                onChange={(e) =>
                                                    setNumberOfCompensations(parseInt(e.target.value))
                                                }
                                                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700"
                                            >
                                                {[...Array(6)].map((_, i) => (
                                                    <option key={i} value={i}>
                                                        {i}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>
                                        {Array.from({ length: numberOfCompensations }).map((_, i) => (
                                            <div key={i} className="mb-4">
                                                <label className="block text-sm font-bold text-dark-blue">
                                                    Kompenzáció [dátum - összeg (USD)], sorszám: {i + 1}
                                                </label>
                                                <div className="flex space-x-2">
                                                    <input
                                                        type="date"
                                                        value={
                                                            recordData.compensations[i]?.date instanceof Date &&
                                                            !isNaN(recordData.compensations[i]?.date.getTime())
                                                                ? recordData.compensations[i].date.toISOString().split("T")[0]
                                                                : ""
                                                        }                                                       
                                                        onChange={(e) =>
                                                            handleChange(
                                                                "compensations",
                                                                { date: new Date(e.target.value) },
                                                                i
                                                            )
                                                        }
                                                        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700"
                                                    />
                                                    <input
                                                        type="number"
                                                        value={recordData.compensations[i]?.amount || ""}
                                                        onChange={(e) =>
                                                            handleChange(
                                                                "compensations",
                                                                { amount: parseFloat(e.target.value) },
                                                                i
                                                            )
                                                        }
                                                        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700"
                                                    />
                                                </div>
                                            </div>
                                        ))}

                                        <div className="mb-4">
                                            <label className="block text-sm font-bold text-dark-blue">
                                                Túlfizetés (VIP - 01)
                                            </label>
                                            <select
                                                value={numberOfWrongPayouts}
                                                onChange={(e) =>
                                                    setNumberOfWrongPayouts(parseInt(e.target.value))
                                                }
                                                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700"
                                            >
                                                {[...Array(6)].map((_, i) => (
                                                    <option key={i} value={i}>
                                                        {i}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>
                                        {Array.from({ length: numberOfWrongPayouts }).map((_, i) => (
                                            <div key={i} className="mb-4">
                                                <label className="block text-sm font-bold text-dark-blue">
                                                    Kifizetéstöbblet [dátum - összeg (USD)], sorszám: {i + 1}
                                                </label>
                                                <div className="flex space-x-2">
                                                    <input
                                                        type="date"
                                                        value={
                                                            recordData.wrongPayouts[i]?.date instanceof Date &&
                                                            !isNaN(recordData.wrongPayouts[i]?.date.getTime())
                                                                ? recordData.wrongPayouts[i].date.toISOString().split("T")[0]
                                                                : ""
                                                        }
                                                        
                                                        onChange={(e) =>
                                                            handleChange(
                                                                "wrongPayouts",
                                                                { date: new Date(e.target.value) },
                                                                i
                                                            )
                                                        }
                                                        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700"
                                                    />
                                                    <input
                                                        type="number"
                                                        value={recordData.wrongPayouts[i]?.amount || ""}
                                                        onChange={(e) =>
                                                            handleChange(
                                                                "wrongPayouts",
                                                                { amount: parseFloat(e.target.value) },
                                                                i
                                                            )
                                                        }
                                                        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700"
                                                    />
                                                </div>
                                            </div>
                                        ))}
                                    </form>
                                </div>
                                <div className="flex items-center justify-end p-6 border-t border-solid border-gray-300 rounded-b">
                                    <button
                                        className="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm"
                                        type="button"
                                        onClick={closeModal}
                                    >
                                        Mégse
                                    </button>
                                    <button
                                        className="text-white bg-success font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg"
                                        type="button"
                                        onClick={makeRecord}
                                    >
                                        Mentés
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="fixed inset-0 bg-black bg-opacity-50"></div>
                </div>
            )}
        </>
    );
};

export default EditDaisyExitDetailsModal;