import React from 'react';
import { getEnvelopeList } from '../../api/getEnvelopeList';
import { Envelope } from '../../models/envelope.model';
import { dateFormatter } from '../../utils/dateFormatter';
import { responseHero } from '../../utils/axiosInterceptor';
import { toast } from 'react-toastify';
import PairEnvelopeToDepositRegister from './modals/PairEnvelopeToDepositRegister';
import PairEnvelopeToTemplate from './modals/PairEnvelopeToTemplate';

interface ContractHistoryProps {
    archivedListOpen: boolean;
}
export default function ContractHistory ({ archivedListOpen } : ContractHistoryProps) {

    const [showModal, setShowModal] = React.useState<boolean>(false)
    const [showTemplateModal, setShowTemplateModal] = React.useState<boolean>(false)

    const [envelopes, setEnvelopes] = React.useState<Envelope[]>([])
    const [spinner, setSpinner] = React.useState<boolean>(true)
    const [archivedListOpenState, setArchivedListOpenState] = React.useState<boolean>(archivedListOpen)
    const [envelopeData, setEnvelopeData] = React.useState<Envelope | null>(null)

    /* Effects */
    React.useEffect(() => {
        setSpinner(true)

        getEnvelopeList(archivedListOpenState)
        .then((response) => {
            setEnvelopes(response)
            setSpinner(false)
        })
        .catch((error) => {
            console.error(error);
            setSpinner(false)
        });

    }, [archivedListOpenState])
    
    const changeContractArchivedStatus = async (env: Envelope) => {
        try {
            const updatedArchivedStatus = !archivedListOpenState;

            const envelope = {
                archived: updatedArchivedStatus
            };

            await responseHero.patch(`${process.env.REACT_APP_API_URL}/envelope/${env?.envelope_id}`, { envelope: envelope });
    
            setEnvelopes(prevEnvelopes =>
                prevEnvelopes.filter(e => e.envelope_id !== env.envelope_id) // Remove from the current list
            );
    
            toast.success(`Sikeresen változtattad a szerződés állapotát!`);
        } catch (error: any) {
            console.error(JSON.stringify(error.response.data.message, null, 2));
            toast.error(`Sikertelen módosítási kísérlet!`);
        }
    };

    const updateEnvelopeStatus = async (refId: string) => {
        try {
            const result = await responseHero.get(`${process.env.REACT_APP_API_URL}/envelopestatus/${refId}`);
            updateEnvelopeInList(result.data.envelope);
            
            toast.success(`Sikeresen frisstetted a szerződés státuszát!`);
        } catch (error: any) {
            console.error(JSON.stringify(error.response.data.message, null, 2));
            toast.error(`Sikertelen frissítési kísérlet!`);
        }
    };

    const openModal = (envelope: Envelope) => {
        setEnvelopeData(envelope);
        setShowModal(true);
    }

    const openTemplateModal = (envelope: Envelope) => {
        setEnvelopeData(envelope);
        setShowTemplateModal(true);
    }

    const updateEnvelopeInList = (updatedEnvelope: Envelope) => {
        setEnvelopes((prevEnvelopes) =>
            prevEnvelopes.map(env => env.envelope_id === updatedEnvelope.envelope_id ? updatedEnvelope : env)
        );
    };
    
    return (
        <>
            {((showModal || showTemplateModal) && envelopeData) && (
                <>
                    <PairEnvelopeToDepositRegister refreshEnvelope={updateEnvelopeInList} title={'Szerződés párosítása befizetéshez'} showModal={showModal} closeModal={() => setShowModal(false)} envelopeData={envelopeData}/>
                    <PairEnvelopeToTemplate refreshEnvelope={updateEnvelopeInList} title={'Szerződés párosítása sablonhoz'} showModal={showTemplateModal} closeModal={() => setShowTemplateModal(false)} envelopeData={envelopeData}/>
                </>
            )}
        
            <div className="m-20">
            <p className="text-2xl text-center text-dark-brown mb-5 font-bold">
                {archivedListOpenState ? "Archivált szerződések" : "Élő szerződések"}
            </p>

            <div className="flex flex-row items-center justify-center text-center mb-2 mt-2">
                <button onClick={() => setArchivedListOpenState(!archivedListOpenState)} className="py-3 px-2 bg-success rounded-lg shadow-md text-white hover:opacity-80">
                    {archivedListOpenState ? "Vissza az élő szerződésekhez" : "Archivum megtekintése"}
                </button>
            </div>

            {spinner ? <p className='animation-pulse text-center mt-5 text-lg font-semibold'>Kérlek várj még a szerződések betöltődnek...</p> :
            <div style={{ maxHeight: 600, overflowY: 'auto' }}>
            <div className="w-full border border-gray-600 rounded-lg shadow bg-dark-blue">
                <table className="w-full divide-y table-fixed divide-gray-600 shadow-lg">

                    <thead className="bg-dark-blue sticky top-0">
                        <tr>
                            <th scope="col" className="py-3 px-6 text-xs font-medium tracking-wider text-center text-cream uppercase">ID</th>
                            <th scope="col" className="py-3 px-6 text-xs font-medium tracking-wider text-center text-cream uppercase">Referencia szám</th>
                            <th scope="col" className="py-3 px-6 text-xs font-medium tracking-wider text-center text-cream uppercase">Státusz</th>
                            <th scope="col" className="py-3 px-6 text-xs font-medium tracking-wider text-center text-cream uppercase">Sablon neve</th>
                            <th scope="col" className="py-3 px-6 text-xs font-medium tracking-wider text-center text-cream uppercase">Idő</th>
                            <th scope="col" className="py-3 px-6 text-xs font-medium tracking-wider text-center text-cream uppercase">Ügyfél</th>
                            <th scope="col" className="py-3 px-6 text-xs font-medium tracking-wider text-center text-cream uppercase">Befizetés rögzítve</th>
                            <th scope="col" className="py-3 px-6 text-xs font-medium tracking-wider text-center text-cream uppercase">Összeg</th>
                            <th scope="col" colSpan={3} className="sr-only">Kezelés</th>
                        </tr>
                    </thead>
                    <tbody className="bg-dark-blue divide-y divide-gray-600">
                        {envelopes.map((env, index) => ( 
                            <tr key={index} className="hover:bg-light-blue">
                                <td className="py-4 px-6 text-sm font-medium text-white text-center">{env.envelope_id}</td>
                                <td className="py-4 px-6 text-sm font-medium text-white text-center">{env.envelope_ref_id}</td>
                                <td className="py-4 px-6 text-sm font-medium text-white text-center">{env.status}</td>
                                <td className="py-4 px-6 text-sm font-medium text-white text-center">{env.template?.template_name} 
                                    <p className='text-cream font-semibold'>(ID: {env.template_id})
                                    </p>
                                </td>
                                <td className="py-4 px-6 text-sm font-medium text-white text-center">{dateFormatter(env.statusDateTime.toString())}</td>
                                <td className="py-4 px-6 text-sm font-medium text-white text-center">{env.signer?.name}</td>
                                <td className={`py-4 px-6 text-sm font-medium ${env.depositRegister ? 'text-emerald-300' : 'text-white'} text-center`}>
                                    {env.depositRegister ?
                                        env.depositRegister?.user?.name + "\n(" + env.depositRegister?.group?.group_name + ")" : 
                                        "Nincs rögzítve"}
                                </td>
                                <td className="py-4 px-6 text-sm font-medium text-white text-center">{env.invested_amount} {env.currency}</td>

                                <td>
                                    <p onClick={() => {
                                        openModal(env)
                                    }} className="text-cream hover:text-brown text-md font-bold text-center p-2 cursor-pointer">
                                        {"Párosítás H.D.C. befizetéshez"}
                                    </p>
                                </td>

                                <td>
                                    <p onClick={() => {
                                        openTemplateModal(env)
                                    }} className="text-cream hover:text-brown text-md font-bold text-center p-2 cursor-pointer">
                                        {"Sablon választása"}
                                    </p>
                                </td>
                                
                                <td >
                                    <p onClick={() => {
                                        updateEnvelopeStatus(env.envelope_ref_id)
                                    }} className="text-cream hover:text-brown text-md font-bold text-center p-2 cursor-pointer">
                                        {"Státusz frissítése"}
                                    </p>
                                </td>

                                <td >
                                    <p onClick={() => {
                                        changeContractArchivedStatus(env)
                                    }} className="text-cream hover:text-brown text-md font-bold text-center p-2 cursor-pointer">
                                        {archivedListOpenState ? "Visszaállítás az archivumból" : "Archivumba helyezés"}
                                    </p>
                                </td>


                            </tr>
                        ))}
                    </tbody>
                </table>
                
            </div>
            </div>
            }
            </div>
        </>
    )
}
