import React, { Fragment } from "react";
import { getDaisySumInfoByTotalGroup } from "../../api/getDaisySumInfoByUserGroup";
import { useSelector } from "react-redux";
import { selectMenuStates } from "../../store/reducers/menuSlice";
import { GroupSummarization, UserEntity, UserEntityWithClosureData } from "../../models/types/DaisySummarize";
import { currencyFormatter } from "../../utils/currencyFormatter";
import { toast } from "react-toastify";
import { responseHero } from "../../utils/axiosInterceptor";
import { getDatesForDaisy } from "../../api/getDatesForDaisy";

export default function TotalDaisyPayoutManager() {

    const [total, setTotal] = React.useState<GroupSummarization>();
    const [totalOldInvestments, setTotalOldInvestments] = React.useState<number>(0);

    const [users, setUsers] = React.useState<UserEntity[]>([]);
    const [under100Users, setUnder100Users] = React.useState<UserEntity[]>([]);
    const [filteredCapitalRatedUsers, setFilteredCapitalRatedUsers] = React.useState<UserEntityWithClosureData[]>([]);
    const [filteredHumanCompensations, setFilteredHumanCompensations] = React.useState<UserEntityWithClosureData[]>([]);

    const [loading, setLoading] = React.useState<boolean>(false);

    const removeCacheData = () => {
        localStorage.removeItem('cachedTotal');
        localStorage.removeItem('cachedTotalOldInvestments');
        localStorage.removeItem('cachedUnder100Users');
        localStorage.removeItem('cachedFilteredCapitalRatedUsers');
        localStorage.removeItem('cachedFilteredHumanCompensations');

        toast.success('Sütik sikeresen törölve!');
    }

    React.useEffect(() => {
        const cachedTotal = localStorage.getItem('cachedTotal');
        const cachedTotalOldInvestments = localStorage.getItem('cachedTotalOldInvestments');

        const cachedUsers = localStorage.getItem('cachedUsers');
        const cachedUnder100Users = localStorage.getItem('cachedUnder100Users');
        const cachedFilteredCapitalRatedUsers = localStorage.getItem('cachedFilteredCapitalRatedUsers');
        const cachedFilteredHumanCompensations = localStorage.getItem('cachedFilteredHumanCompensations');
    
        if (cachedTotal && cachedUsers && cachedUnder100Users && cachedFilteredCapitalRatedUsers && cachedFilteredHumanCompensations && cachedTotalOldInvestments) {
            setTotal(JSON.parse(cachedTotal));
            setUsers(JSON.parse(cachedUsers));
            setUnder100Users(JSON.parse(cachedUnder100Users));
            setTotalOldInvestments(JSON.parse(cachedTotalOldInvestments));
            setLoading(false);
        } else {
            setLoading(true);
            getDaisySumInfoByTotalGroup()
                .then((response) => {
                    setLoading(false);
                    setUsers(response.users);
                    setTotal(response.total);

                    if (response.under100Users) {
                        setUnder100Users(response.under100Users);
                    }

                    if (response.filteredCapitalRatedUsers) {
                        setFilteredCapitalRatedUsers(response.filteredCapitalRatedUsers);
                    }

                    if (response.filteredHumanCompensations) {
                        setFilteredHumanCompensations(response.filteredHumanCompensations);
                    }

                    if(response.totalOldInvestments) {
                        setTotalOldInvestments(response.totalOldInvestments);
                    }
    
                    // Cache results
                    localStorage.setItem('cachedTotal', JSON.stringify(response.total));
                    localStorage.setItem('cachedUsers', JSON.stringify(response.users));
                    localStorage.setItem('cachedUnder100Users', JSON.stringify(response.under100Users));
                    localStorage.setItem('cachedFilteredCapitalRatedUsers', JSON.stringify(response.filteredCapitalRatedUsers));
                    localStorage.setItem('cachedFilteredHumanCompensations', JSON.stringify(response.filteredHumanCompensations));
                    localStorage.setItem('cachedTotalOldInvestments', JSON.stringify(response.totalOldInvestments));
                })
                .catch((error) => {
                    setLoading(false);
                    console.error(error);
                });
        }
    }, []);
    


    return (
        <>  
            <div className="m-5 p-3">
                {loading ?
                    <p className="text-xl text-center text-dark-blue animate-pulse">Kérlek várj még az adatok lekérdezésre kerülnek...</p>
                :
                    <>
                        <button className="uppercase text-sm px-2 py-2 bg-brown rounded text-white opacity-90 hover:opacity-100" onClick={removeCacheData}>
                            Sütik törlése
                        </button>
                        {(total) && (
                            <SummarizationPanel data={total}/>
                        )}

                        {(under100Users.length > 0) && (
                            <UserEntitiesPanel under100Users={under100Users} filteredCapitalRatedUsers={filteredCapitalRatedUsers} filteredHumanCompensations={filteredHumanCompensations}/>
                        )}

                        <FieldSelectorPanel totalOldInvestments={totalOldInvestments}/>
                    </>
                }
            </div>
        </>
    );

}

type SummarizationPanelProps = {
    data: GroupSummarization;
}

type UserEntitiesPanelProps = {
    under100Users: UserEntity[];
    filteredCapitalRatedUsers: UserEntityWithClosureData[];
    filteredHumanCompensations: UserEntityWithClosureData[];
}

type FieldSelectorPanelProps = {
    totalOldInvestments: number;
}

const UserEntitiesPanel = ({ under100Users, filteredCapitalRatedUsers, filteredHumanCompensations }: UserEntitiesPanelProps) => {

    const totalUnder100UsersDifferences = under100Users.map((entity) => {
        return {
            difference: entity.invested.value_eur - entity.total_profit.value_eur
        }
    }).reduce((acc, curr) => acc + curr.difference, 0);

    const totalFilteredCapitalRates = filteredCapitalRatedUsers.map((entity) => {
        return {
            difference: entity.closure.capital_ratio - entity.entity.invested.value_usd
        }
    }).reduce((acc, curr) => acc + curr.difference, 0);

    const totalFilteredCompensations = filteredHumanCompensations.map((entity) => {
        return entity.closure.compensations.map((comp) => {
            return comp.amount;
        }).reduce((acc, curr) => acc + curr, 0);
    }).reduce((acc, curr) => acc + curr, 0);

    return (
        <div className="mt-6 mb-6 p-6 border rounded-xl bg-white shadow-lg shadow-gray-200">
            <div className="flex flex-col lg:flex-row space-y-6 lg:space-y-0 lg:space-x-8">
                {/* Left Section */}
                <div className="w-full lg:w-1/3">
                    <h1 className="text-xl font-bold text-center mb-6 text-gray-800">
                        100% alatt teljesített fiókok
                    </h1>
                    <p className="text-lg text-gray-600 mb-2">
                        Összese különbözet: <span className="font-semibold text-dark-blue">{currencyFormatter(totalUnder100UsersDifferences, 'EUR')}</span>
                    </p>
                    {under100Users.map((user, index) => (
                        <div 
                            key={index} 
                            className="hover:cursor-pointer rounded-lg bg-gray-200 p-5 mb-5 transition-transform transform hover:scale-105 hover:shadow-md hover:bg-gray-300"
                        >
                            <p className="text-sm text-gray-700 mt-2">
                                Név: <span className="font-medium text-dark-blue">
                                    {user.name} - ({user.group_name})
                                </span>
                            </p>
                            <p className="text-sm text-gray-700 mt-2">
                                Befizetett: <span className="font-medium text-dark-blue">
                                    {currencyFormatter(user.invested.value_eur, 'EUR')}
                                </span>
                            </p>
                            <p className="text-sm text-gray-700 mt-2">
                                    Kereskedésből össz kifizetés: <span className="font-medium text-dark-blue">
                                    {currencyFormatter(user.total_profit.value_eur, 'EUR')}
                                </span>
                            </p>
                            <p className="text-sm text-gray-700 mt-2">
                                Különbség: <span className="font-medium text-dark-blue">
                                    {currencyFormatter(
                                        (user.invested.value_eur - user.total_profit.value_eur), 'EUR')}
                                </span>
                            </p>
                        </div>
                    ))}
                </div>
                {/* Right Section */}
                <div className="w-full lg:w-1/3">
                    <h1 className="text-xl font-bold text-center mb-6 text-gray-800">
                        Tőkeviszonyszámmal rendelkező ügyfelek
                    </h1>
                    <p className="text-lg text-gray-600 mb-2">
                        Összese különbözet: <span className="font-semibold text-dark-blue">{currencyFormatter(totalFilteredCapitalRates, 'USD')}</span>
                    </p>
                    {filteredCapitalRatedUsers.map((user, index) => (
                        <div 
                            key={index} 
                            className="hover:cursor-pointer rounded-lg bg-gray-200 p-5 mb-5 transition-transform transform hover:scale-105 hover:shadow-md hover:bg-gray-300"
                        >
                            <p className="text-sm text-gray-700 mt-2">
                                Név: <span className="font-medium text-dark-blue">
                                    {user.entity.name} - ({user.entity.group_name})
                                </span>
                            </p>
                            <p className="text-sm text-gray-700 mt-2">
                                Befizetett: <span className="font-medium text-dark-blue">
                                    {currencyFormatter(user.entity.invested.value_usd, 'USD')}
                                </span>
                            </p>
                            <p className="text-sm text-gray-700 mt-2">
                                Tőkeviszonyszám: <span className="font-medium text-dark-blue">
                                    {currencyFormatter(user.closure.capital_ratio, 'USD')}
                                </span>
                            </p>
                        </div>
                    ))}
                </div>
                {/* Right Section */}
                <div className="w-full lg:w-1/3">
                    <h1 className="text-xl font-bold text-center mb-6 text-gray-800">
                        Human D.C -ből kifizetett ügyfelek
                    </h1>
                    <p className="text-lg text-gray-600 mb-2">
                        Összesen kifizetve: <span className="font-semibold text-dark-blue">{currencyFormatter(totalFilteredCompensations, 'USD')}</span>
                    </p>
                    {filteredHumanCompensations.map((user, index) => (
                        <div 
                            key={index} 
                            className="rounded-lg bg-gray-200 p-5 mb-5 transition-transform transform hover:scale-105 hover:shadow-md hover:bg-gray-300"
                        >
                            <p className="text-sm text-gray-700 mt-2">
                                Név: <span className="font-medium text-dark-blue">
                                    {user.entity.name} - ({user.entity.group_name})
                                </span>
                            </p>
                            {user.closure.compensations.map((comp) => (
                                <p className="text-sm text-gray-700 mt-2">
                                Kompenzáció: <span className="font-medium text-dark-blue">
                                    {comp.date.toString().split('T')[0]}: {currencyFormatter(comp.amount, 'USD')}
                                </span>
                                </p>
                            ))}
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

const FieldSelectorPanel = ({ totalOldInvestments }: FieldSelectorPanelProps) => {
    const [percentage, setPercentage] = React.useState(0);
    const [tradeCloseDate, setTradeCloseDate] = React.useState("2024-11-22");
    const [currentDate, setCurrentDate] = React.useState(() => new Date().toISOString().split('T')[0]);

    React.useEffect(() => {
        getDatesForDaisy().then((response) => {
            const convertToDateCloseDate = response?.closeDate?.toString()?.split("T")[0];
            const convertToDateTodaysDate = response?.todaysDate?.toString()?.split("T")[0];
            setCurrentDate(convertToDateTodaysDate)
            setTradeCloseDate(convertToDateCloseDate)
        })
    }, [totalOldInvestments])

    const updateRecord = async () => {
        const tempCurrentDate = currentDate.toString().split('T')[0];
        const tempTradeCloseDate = tradeCloseDate.toString().split('T')[0];

        await responseHero.put(`${process.env.REACT_APP_API_URL}/daisyclosuredate/`, { todaysDate: tempCurrentDate, closeDate: tempTradeCloseDate }).then((response) => {
            toast.success(`Sikeresen frissítetted a dátumokat!`);
        }).catch(error => {
            console.error(JSON.stringify(error.response.data.message, null, 2));
            toast.error(`Sikertelen frissítés!`);
        })
    }

    const handlePercentageChange = (event: any) => {
        const value = Number(event.target.value); // Clamp value between 1 and 100
        setPercentage(value);
    };

    const calculatedValue = ((totalOldInvestments * percentage) / 100).toFixed(2);

    const yesterday = new Date();
    yesterday.setDate(yesterday.getDate() - 1);
    const maxDate = yesterday.toISOString().split("T")[0];

    return (
        <div className="p-6 border rounded-lg shadow-md bg-gray-50 flex space-x-2">
            <div className="w-1/3 p-4 bg-gray-400 rounded shadow-md shadow-gray-200">
                <p className="text-xl font-bold text-gray-800">
                    Összes befizetés (2021 - 22) USD: <span className="text-cream">{totalOldInvestments}$</span>
                </p>
                <div className="mt-4">
                    <label htmlFor="percentageInput" className="block text-gray-700 font-medium mb-2">
                        Írjon be egy százalékot (1-100):
                    </label>
                    <input
                        id="percentageInput"
                        type="number"
                        value={percentage}
                        onChange={handlePercentageChange}
                        className="w-full px-3 py-2 border rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                    />
                    <p className="mt-3 text-gray-600 font-medium">
                        Az aktuális százalékos érték: <span className="text-cream font-bold">{Math.round(parseInt(calculatedValue, 10))}$</span>
                    </p>
                </div>
            </div>

            <div className="w-1/3 p-4 bg-gray-400 rounded shadow-md shadow-gray-200">
                <label className="block text-gray-700 font-medium mb-2">Kereskedés lezárásának dátuma:</label>
                <input
                    type="date"
                    max={maxDate}
                    value={tradeCloseDate}
                    onChange={(e) => setTradeCloseDate(e.target.value)}
                    className="w-full px-3 py-2 border rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                />

                <button onClick={updateRecord} className="mt-2 rounded opacity-90 hover:opacity-100 text-lg bg-dark-blue text-gray-100 px-1 py-1">
                    Beállítom
                </button>
            </div>

            <div className="w-1/3 p-4 bg-gray-400 rounded shadow-md shadow-gray-200">
                <label className="block text-gray-700 font-medium mb-2">Jelenlegi dátum:</label>
                <input
                    type="date"
                    max={maxDate}
                    value={currentDate}
                    onChange={(e) => setCurrentDate(e.target.value)}
                    className="w-full px-3 py-2 border rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                />

                <button onClick={updateRecord} className="mt-2 rounded opacity-90 hover:opacity-100 text-lg bg-dark-blue text-gray-100 px-1 py-1">
                    Beállítom
                </button>
            </div>
        </div>
    );
};

const SummarizationPanel = ({ data }: SummarizationPanelProps) => {
    const menu = useSelector(selectMenuStates);

    return (
        <div className="mt-2 mb-2 px-2 py-2 border rounded bg-white shadow-lg shadow-gray-400 mb-5">
            <h1 className="text-xl font-semibold text-center py-4">D.A.I.SY Eredmények - Összesítve</h1>
            <div className="flex flex-row space-x-4">
                {/* Block 1 */}
                <div className="rounded bg-gray-200 px-2 py-2 w-full">
                    <p className="text-md text-dark-blue flex justify-between">
                        Összesen befizetett USD:
                        <span className="font-semibold">{currencyFormatter(data.totalInvested.value_usd, 'USD')}</span>
                    </p>
                    <p className="text-md text-dark-blue flex justify-between">
                        Összesen befizetett EUR:
                        <span className="font-semibold">{currencyFormatter(data.totalInvested.value_eur, 'EUR')}</span>
                    </p>
                    <p className="text-md text-dark-blue flex justify-between mt-2">
                        Összesen befizetett (Crypto):
                        <span className="font-semibold">{currencyFormatter(data.totalDeposit.in_crypto, 'USD')}</span>
                    </p>
                    <p className="text-md text-dark-blue flex justify-between">
                        Összesen befizetett (Forex):
                        <span className="font-semibold">{currencyFormatter(data.totalDeposit.in_forex, 'USD')}</span>
                    </p>
                    <p className="text-md text-dark-blue flex justify-between mt-2">
                        Összesen a kereskedésbe került (Crypto):
                        <span className="font-semibold">{currencyFormatter(data.totalInTrade.in_crypto, 'USD')}</span>
                    </p>
                    <p className="text-md text-dark-blue flex justify-between">
                        Összesen a kereskedésbe került (Forex):
                        <span className="font-semibold">{currencyFormatter(data.totalInTrade.in_forex, 'USD')}</span>
                    </p>
                    <p className="text-md text-dark-blue flex justify-between">
                        Összesen a kereskedésbe került (USD):
                        <span className="font-semibold">{currencyFormatter(data.totalInTrade.in_usd, 'USD')}</span>
                    </p>
                    <p className="text-md text-dark-blue flex justify-between">
                        Összesen a kereskedésbe került (EUR):
                        <span className="font-semibold">{currencyFormatter(data.totalInTrade.in_eur, 'EUR')}</span>
                    </p>
                    <p className="text-md text-dark-blue flex justify-between mt-2 mb-2">
                        Összes különbözet USD:
                        <span className="font-semibold">{currencyFormatter(data.totalDifference.value_usd, 'USD')}</span>
                    </p>
                </div>

                {/* Block 2 */}
                <div className="rounded bg-gray-200 px-2 py-2 w-full">
                    <p className="text-md text-dark-blue flex justify-between">
                        Összesen eddig kikért USD:
                        <span className="font-semibold">{currencyFormatter(data.totalAssigned.value_usd, 'USD')}</span>
                    </p>
                    <p className="text-md text-dark-blue flex justify-between">
                        Összesen még kikérhető USD:
                        <span className="font-semibold">{currencyFormatter(data.totalRequestable.value_usd, 'USD')}</span>
                    </p>
                    <p className="text-md text-dark-blue flex justify-between">
                        Kereskedésből össz kifizetés USD:
                        <span className="font-semibold">{currencyFormatter(data.totalProfit.value_usd, 'USD')}</span>
                    </p>
                    <p className="text-md text-dark-blue flex justify-between">
                        Százalékban kifejezve:
                        <span className="font-semibold text-emerald-600">{data.totalRate}%</span>
                    </p>
                </div>
                {/* Block 3 */}
                <div className="rounded bg-gray-200 px-2 py-2 w-full">
                    <p className="text-md text-dark-blue flex justify-between">
                        Összesen fejlesztésre elvont összeg USD:
                        <span className="font-semibold">{currencyFormatter(data.totalInUpdate.value_usd, 'USD')}</span>
                    </p>
                    <p className="text-md text-dark-blue flex justify-between">
                        Összes részvény darabszám a csoportban:
                        <span className="font-semibold">{data.totalShareAmount} db</span>
                    </p>
                    <p className="text-md text-dark-blue flex justify-between">
                        Törzsrészvény bekerülési költsége/db
                        <span className="font-semibold">{currencyFormatter(data.totalSharePerUpdatePrice, 'USD')}</span>
                    </p>
                </div>
                {/* Block 4 */}
                <div className="rounded bg-gray-200 px-2 py-2 w-full">
                
                    <p className="text-md text-dark-blue flex justify-between">
                        Összesen a kereskedésből kifizetve USD:
                        <span className="font-semibold">{currencyFormatter(data.totalAllocation, 'USD')}</span>
                    </p>

                    <p className="text-md text-dark-blue flex justify-between">
                        Ügyfélnek már kifizetett jutalék USD:
                        <span className="font-semibold">{currencyFormatter(data.totalSent.to_client, 'USD')}</span>
                    </p>
                    <p className="text-md text-dark-blue flex justify-between">
                        A cégnek már kiutalva USD:
                        <span className="font-semibold">{currencyFormatter(data.totalSent.to_company, 'USD')}</span>
                    </p>

                    <p className="text-md text-dark-blue flex justify-between mt-5">
                        Ügyfelek megtérüléséhez szükséges (EUR):
                        <span className="font-semibold">{currencyFormatter(data.totalDifferenceBetweenInvestmentAndPaid?.value_eur, 'EUR')}</span>
                    </p>
                    <p className="text-md text-dark-blue flex justify-between">
                        Ügyfelek megtérüléséhez szükséges (USD):
                        <span className="font-semibold">{currencyFormatter(data.totalDifferenceBetweenInvestmentAndPaid?.value_usd, 'USD')}</span>
                    </p>

                    <p className="text-md text-dark-blue flex justify-between mt-5">
                        Kereskedés megtérüléséhez szükséges (USD):
                        <span className="font-semibold">{currencyFormatter(data.totalTradeSentDifference?.value_usd, 'USD')}</span>
                    </p>
                    <p className="text-md text-dark-blue flex justify-between">
                        Kereskedés megtérüléséhez szükséges (EUR):
                        <span className="font-semibold">{currencyFormatter(data.totalTradeSentDifference?.value_eur, 'EUR')}</span>
                    </p>
                    

                </div>
            </div>

            {/* Block 5 */}
            <div className="border-b-2 border-dark-blue mt-2 mb-2 w-full"></div>

            <div className="rounded bg-gray-300 px-2 py-2 w-2/4">
                <p className="text-md text-dark-blue flex justify-between">
                    Ügyfélnek fizetésre váró jutalék (Záráskor) (USD): 
                    <span className="font-semibold text-orange-400">{currencyFormatter(data?.totalStillToBePaid?.client?.at_closure?.value_usd, 'USD')}</span>
                </p>
                <p className="text-md text-dark-blue flex justify-between mb-5">
                    Ügyfélnek fizetésre váró jutalék (Ma) (USD): 
                    <span className="font-semibold text-orange-400">{currencyFormatter(data?.totalStillToBePaid?.client?.at_today?.value_usd, 'USD')}</span>
                </p>

                <p className="text-md text-dark-blue flex justify-between">
                    Cégnek fizetésre váró jutalék (Záráskor) (USD): 
                    <span className="font-semibold text-orange-400">{currencyFormatter(data?.totalStillToBePaid?.company?.at_closure?.value_usd, 'USD')}</span>
                </p>
                <p className="text-md text-dark-blue flex justify-between mb-5">
                    Cégnek fizetésre váró jutalék (Ma) (USD): 
                    <span className="font-semibold text-orange-400">{currencyFormatter(data?.totalStillToBePaid?.company?.at_today?.value_usd, 'USD')}</span>
                </p>
                
                <p className="text-md text-dark-blue flex justify-between">
                    Ügyfél: Különbség a mai - záráskori (USD): 
                    <span className="font-semibold text-orange-800">{currencyFormatter(data?.totalStillToBePaidDifference?.client?.value_usd, 'USD')}</span>
                </p>
                <p className="text-md text-dark-blue flex justify-between mb-5">
                    Cég: Különbség a mai - záráskori (USD): 
                    <span className="font-semibold text-orange-800">{currencyFormatter(data?.totalStillToBePaidDifference?.company?.value_usd, 'USD')}</span>
                </p>

                <p className="text-md text-dark-blue flex justify-between">
                    Ügyfélnek fizetésre váró jutalék (EUR): 
                    <span className="font-semibold text-emerald-600">{currencyFormatter(data?.totalStillToBePaid?.client?.at_closure?.value_eur, 'EUR')}</span>
                </p>
                <p className="text-md text-dark-blue flex justify-between">
                    Cégnek fizetésre váró jutalék (EUR): 
                    <span className="font-semibold text-emerald-600">{currencyFormatter(data?.totalStillToBePaid?.company?.at_closure?.value_eur, 'EUR')}</span>
                </p>
            </div>
        </div>
    );
};



