import React, { useEffect, useRef, useState } from "react";
import { Chart, ChartConfiguration, registerables } from "chart.js";
import { CandlestickController, CandlestickElement } from "chartjs-chart-financial";
import "chartjs-adapter-date-fns";
import zoomPlugin from "chartjs-plugin-zoom";
import { getBinanceRates } from "../../../api/getBinanceRates";
import { getHumanSimulationData } from "../../../api/getSimulationData";
import { BinanceRates } from "../../../models/binancerates.model";
import { PreparedData, RecordType } from "../../../models/simulation/CommonTypes";
import { HumanSummarizationData } from "../../../models/simulation/HumanSummarizationData";
import { HumanSimulationResults } from "../../../models/simulation/HumanSimulation";
import { Assets } from "../../../enums/assets";
import UserSelectModal from "../../../components/humandc/modals/simulation/UserSelectModal";
import { User } from "../../../models/user.model";
import { getUserDepositsData } from "../../../api/getUserDepositsData";
import { DepositRegister } from "../../../models/depositregister";
import { useLocation } from "react-router-dom";
import { Systems } from "../../../enums/systems";
import CustomPieChart from "./components/PieChart";
import Decimal from "decimal.js";


//
type AssetType = typeof Assets[keyof typeof Assets];

type PieChartType = {
  name: string;
  value: number | Decimal;
};

// Register Chart.js components
Chart.register(
  ...registerables,
  CandlestickController,
  CandlestickElement,
  zoomPlugin
);

const HumanNewSimulation = () => {

  const location = useLocation();
  const user = location.state?.selectedUser;
  
  const [asset, setAsset] = useState<AssetType>(Assets.BTC);

  const [preData, setPreData] = useState<PreparedData | null>(null);
  const [simData, setSimData] = useState<HumanSimulationResults | null>(null);
  const [summarizationData, setSummarizationData] = useState<HumanSummarizationData | null>(null);

  const [data, setBinanceRates] = useState<BinanceRates[] | null>(null);

  const [showUserModal, setShowUserModal] = useState(false);
  const [selectedUser, setSelectedUser] = useState<User | null>(null);

  const [userDeposits, setUserDeposits] = React.useState<DepositRegister[]>([]);
  const [selectedDeposit, setSelectedDeposit] = useState<DepositRegister | null>(null);

  const [sortedTransactions, setSortedTransactions] = useState<
  { date: Date; amount: number; executionPrice: number; type: string; recordType: number }[]
  >([]);

  const chartRef = useRef<HTMLCanvasElement>(null);
  const chartInstance = useRef<Chart | null>(null);

  const [pieChartData, setPieChatData] = React.useState<PieChartType[]>();

  useEffect(() => {
    if (user) {
      setSelectedUser(user);
    }
  }, [user]);
  
  useEffect(() => {
    if (!selectedUser) return;
  
    const fetchDeposits = async () => {
      try {
        const result = await getUserDepositsData(selectedUser.user_id, Systems.HPM);
        setUserDeposits(result);
  
        // Alapértelmezetten az első befektetés kiválasztása
        if (result.length > 0) {
          setSelectedDeposit(result[0]);
        } else {
          setSelectedDeposit(null);
        }
      } catch (error) {
        console.error("Hiba a befektetések lekérésekor:", error);
      }
    };
  
    fetchDeposits();
  }, [selectedUser, asset]);  

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await getBinanceRates(asset);
        setBinanceRates(res);
      } catch (error) {
        console.error("Hiba az API hívásban:", error);
      }
    };
  
    fetchData();
  }, [asset]);

  useEffect(() => {
    if (!selectedDeposit) return;
  
    const fetchSimData = async () => {
      try {
        const res = await getHumanSimulationData(selectedDeposit.id);
        setSimData(res.humanSimulationState);
        setPreData(res.preparedData)
        setSummarizationData(res.summarization)

        // generate pie chart
        const cost_deduction = res.preparedData.depositRegisterData?.cost_deduction || 0;
        const deduction_alt = res.preparedData.depositRegisterData?.deduction_alt || 0;
        const deduction_mining = res.preparedData.depositRegisterData?.deduction_mining || 0;
        const deduction_physical_gold = res.preparedData.depositRegisterData?.deduction_physical_gold || 0;

        const liquidity = res.preparedData.depositRegisterData?.liquidity || 1;

        const starterCoins = res.preparedData.assetPurchasesData.reduce((acc, entity) => {
          const amount = parseFloat(entity.amount.toString());
          if (amount > 0) {
            acc.push({
              name: entity.asset.name,
              value: amount
            });
          }
          return acc;
        }, [] as { name: string; value: number }[]);
        

        let pieChartData = [
          {name: "Likviditás", value: (liquidity / 2)},
          {name: "Human D.C. költségei", value: (cost_deduction)},
          {name: "Altcoinba fektetve", value: (deduction_alt)},
          {name: "Bányagépbe fektetve", value: (deduction_mining)},
          {name: "Aranyba fektetve", value: (deduction_physical_gold)},
        ];

        if(starterCoins.length > 0) {
          pieChartData.push(...starterCoins);
        }

        setPieChatData(pieChartData);

        // Force chart update
        if (chartInstance.current) {
          chartInstance.current.destroy();
          chartInstance.current = null;
        }
      } catch (error) {
        console.error("Hiba az API hívásban:", error);
      }
    };
  
    fetchSimData();
  }, [selectedDeposit, asset]);  

  useEffect(() => {
    if (!simData || !preData || !selectedDeposit) return;
  
    const firstInvestment = preData.assetPurchasesData?.find(
      (purchase) => purchase.assetId === asset
    );
  
    const firstTransaction = firstInvestment
      ? {
          date: new Date(firstInvestment.date),
          amount: parseFloat(firstInvestment.amount?.toString() || "0"),
          executionPrice: parseFloat(firstInvestment.exchange_rate?.toString() || "0"),
          recordType: RecordType.INVESTMENT_TRANSACTION,
          type: "buy",
        }
      : null;
  
    const selectedCoinData = simData.currentState.find((entity) => entity.coinData.assetId === asset)?.coinData;
    if(selectedCoinData) {;
        const transactions = [
        ...(firstTransaction ? [firstTransaction] : []),
        ...(selectedCoinData.buyOperations ?? []).map(({ date, amount, executionPrice, recordType }) => ({
            date: new Date(date),
            amount: parseFloat(amount?.toString() || "0"),
            executionPrice: parseFloat(executionPrice?.toString() || "0"),
            recordType: recordType,
            type: "buy",
        })),
        ...(selectedCoinData.sellOperations ?? []).map(({ date, amount, executionPrice, recordType }) => ({
            date: new Date(date),
            amount: parseFloat(amount?.toString() || "0"),
            executionPrice: parseFloat(executionPrice?.toString() || "0"),
            recordType: recordType,
            type: "sell",
        })),
        ].sort((a, b) => a.date.getTime() - b.date.getTime());

        setSortedTransactions(transactions);
}
  }, [simData, preData, selectedDeposit]); 
  

  useEffect(() => {
    if (!data || !sortedTransactions.length || !preData || !selectedDeposit || !chartRef.current) return;
  
    if (chartInstance.current) {
      chartInstance.current.destroy();
    }
  
    const ctx = chartRef.current.getContext("2d");
    console.log("Chart Ref:", chartRef.current);

    if (!ctx) return;
  
    const candlestickData = data.map(({ date, openPrice, highPrice, lowPrice, closePrice }) => ({
      x: new Date(date).getTime(),
      o: parseFloat(openPrice?.toString() || "0"),
      h: parseFloat(highPrice?.toString() || "0"),
      l: parseFloat(lowPrice?.toString() || "0"),
      c: parseFloat(closePrice?.toString() || "0"),
    }));
  
    const transactions = sortedTransactions.map((tx) => ({
      x: tx.date.getTime(),
      y: tx.executionPrice,
      recordType: tx.recordType,
      type: tx.type,
    }));
    
    const config: ChartConfiguration = {
      type: "candlestick",
      data: {
        datasets: [
          {
            label: "Tranzakció",
            type: "line",
            data: transactions,
            borderColor: "#aaa",
            borderWidth: 1,
            pointRadius: 7,
            pointBackgroundColor: (ctx) => {
              const point = ctx.raw as { x: number; y: number; type: string; recordType: number };
            
              if (point.type === "buy") {
                if (point.recordType === RecordType.SIMPLE_TRANSACTION) return "green"; // 🟢 Vétel
                if (point.recordType === RecordType.INVESTMENT_TRANSACTION) return "white"; // ⚪ Befizetés
                if (point.recordType === RecordType.FROM_GOLD) return "orange"; // 🟠 Aranyból származó vásárlás
              } else if (point.type === "sell") {
                return "red"; // 🔴 Eladás
              }
            
              return "gray"; // Default szín, ha valami kimaradna
            },            
            showLine: true,
          },
          {
            label: "Árfolyam",
            type: "candlestick",
            data: candlestickData,
            borderColor: "#fff",
            backgroundColor: "#2ecc71",
          },
        ],
      },
      options: {
        responsive: true,
        plugins: {
          legend: { labels: { color: "#fff" } },
          zoom: {
            pan: { enabled: true, mode: "x" },
            zoom: { wheel: { enabled: true }, pinch: { enabled: true }, mode: "x" },
          },
        },
        scales: {
          x: { type: "time", grid: { color: "#555" }, ticks: { color: "#fff" } },
          y: { grid: { color: "#555" }, ticks: { color: "#fff" } },
        },
      },
    };
  
    chartInstance.current = new Chart(ctx, config);
  }, [data, sortedTransactions, preData, selectedDeposit]);  

  const handleUserSelect = async (user: User) => {
    setSelectedUser(user);
    setShowUserModal(false);
  
    try {
      const result = await getUserDepositsData(user.user_id, Systems.ALT);
      setUserDeposits(result);
  
      // Alapértelmezetten az első befektetés kiválasztása
      setSelectedDeposit(result.length > 0 ? result[0] : null);
    } catch (error) {
      console.error("Hiba a felhasználó befektetéseinek lekérésekor:", error);
    }
  };

  return (
    <div className="bg-gray-900 p-6 rounded-lg">

      <UserSelectModal
        title="Ügyfél hozzárendelése"
        showModal={showUserModal}
        closeModal={() => setShowUserModal(false)}
        onUserSelect={handleUserSelect}
      />

      <div className="flex justify-between items-center mb-4">
        {/* New User Button (Left) */}
        {/* User Selection & Deposit Selector */}
        <div className="flex items-center gap-4">
          {/* New User Button */}
          <button
            onClick={() => setShowUserModal(true)}
            className="bg-blue-500 text-white px-4 py-2 rounded-lg shadow-md hover:bg-blue-600 transition"
          >
            ➕ Ügyfél kiválasztása
          </button>

          {/* Deposit Selector */}
          {userDeposits.length > 0 ? (
            <select
              value={selectedDeposit?.id || ""}
              onChange={(e) =>
                setSelectedDeposit(userDeposits.find((dep) => dep.id === parseInt(e.target.value)) || null)
              }
              className="p-2 rounded-md bg-gray-800 text-white border border-gray-600 focus:ring-2 focus:ring-blue-500"
            >
              {userDeposits.map((deposit) => (
                <option key={deposit.id} value={deposit.id}>
                  {new Date(deposit.date).toISOString().split("T")[0]} - €{deposit.invested_amount_eur} ({deposit.group.group_name})
                </option>
              ))}
            </select>
          ) : (
            <span className="text-gray-400">Nincs befektetés kiválasztva.</span>
          )}
        </div>

        {/* Asset Selector (Center) */}
        <select
          value={asset}
          onChange={(e) => setAsset(Number(e.target.value) as AssetType)}
          className="p-2 rounded-md bg-gray-800 text-white border border-gray-600 focus:ring-2 focus:ring-brown"
        >
            {simData && simData?.currentState?.map((entity) => (
                <option value={entity.coinData.assetId}>{entity.coinData.asset.name} ({entity.coinData.asset.code})</option>
            ))}
        </select>

        {/* Selected User (Right) */}
        {selectedUser ? (
          <div className="text-white text-sm flex items-center">
            <span className="font-semibold">Jelenlegi Ügyfél:</span>
            <span className="ml-2">{selectedUser.name} ({selectedUser.email})</span>
          </div>
        ) : (
          <div className="w-[200px]"></div> // Placeholder width to maintain alignment
        )}
      </div>


      {/* CHART */}
      <canvas ref={chartRef} className="w-full" />

      {/* TRANSACTION TABLE */}
      <div className="mt-6">
        <h3 className="text-white text-lg font-semibold text-center mb-4">Tranzakciók</h3>
        <div className="overflow-x-auto">
          <table className="w-full text-white border border-gray-700">
            <thead>
              <tr className="bg-gray-800 text-left">
                <th className="p-3 border border-gray-700">Dátum</th>
                <th className="p-3 border border-gray-700">Típus</th>
                <th className="p-3 border border-gray-700">Összeg</th>
                <th className="p-3 border border-gray-700">Ár</th>
              </tr>
            </thead>
            <tbody>
              {sortedTransactions.map((tx, index) => (
                <tr
                  key={index}
                  className={`transition ${tx.type === "buy" ? "bg-gray-900 hover:bg-gray-700" : "bg-gray-900 hover:bg-gray-700"}`}
                >
                  <td className="p-3 border border-gray-700">{tx.date.toISOString().split("T")[0]}</td>
                  <td className="p-3 border border-gray-700">
                    {(tx.type === "buy" && tx.recordType === RecordType.SIMPLE_TRANSACTION) && "🟢 Vétel"}
                    {(tx.type === "sell" && tx.recordType === RecordType.SIMPLE_TRANSACTION) && "🔴 Eladás"}
                    {(tx.type === "buy" && tx.recordType === RecordType.INVESTMENT_TRANSACTION) && "⚪ Befizetés"}
                    {(tx.type === "buy" && tx.recordType === RecordType.FROM_GOLD) && "🟠 Aranyból származó vásárlás"}
                  </td>
                  <td className="p-3 border border-gray-700">{tx.amount.toFixed(2)} USD</td>
                  <td className="p-3 border border-gray-700">{tx.executionPrice.toFixed(2)} USD</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>

{/* SUMMARY SECTION */}
{summarizationData && (
    <div className="mt-6 p-4 bg-gray-800 rounded-lg text-white">
        <h3 className="text-lg font-semibold text-center mb-3">Eszköz elosztás</h3>

        <table className="w-full border-collapse border border-gray-500 text-center">
        <thead>
            <tr className="bg-gray-700">
                <th className="p-2 font-semibold border border-gray-600">Coin</th>
                <th className="p-2 font-semibold border border-gray-600">Kezdeti mennyiség</th>
                <th className="p-2 font-semibold border border-gray-600">Kezdeti ár</th>
                <th className="p-2 font-semibold border border-gray-600">Aktuális mennyiség</th>
                <th className="p-2 font-semibold border border-gray-600">Aktuális ár</th>
                <th className="p-2 font-semibold border border-gray-600">Kezdeti likviditás</th>
                <th className="p-2 font-semibold border border-gray-600">Aktuális likviditás</th>
            </tr>
        </thead>
        <tbody>
        {summarizationData.coinState.map((coin) => (
            <tr>
                <td className="p-2 font-semibold border border-gray-600">{coin.name} ({coin.code})</td>
                <td className="p-2 border border-gray-600">{coin.quantity.previous.toString()} db</td>
                <td className="p-2 border border-gray-600">{coin.price.previous.toString()} USD</td>
                <td className="p-2 border border-gray-600">{coin.quantity.current.toString()} db</td>
                <td className="p-2 border border-gray-600">{coin.price.current.toString()} USD</td>
                <td className="p-2 border border-gray-600">{coin.liquidity.previous.toString() || ""} USD</td>
                <td className="p-2 border border-gray-600">{coin.liquidity.current.toString() || ""} USD</td>
            </tr>
        ))}
        </tbody>
        </table>
    </div>
    )}

    {summarizationData && (
    <div className="mt-6 p-4 bg-gray-800 rounded-lg text-white">
        <h3 className="text-lg font-semibold text-center mb-3">Elvonás</h3>

        <table className="w-full border-collapse border border-gray-500 text-center">
        <thead>
            <tr className="bg-gray-700">
            <th className="p-2 font-semibold border border-gray-600">Típus</th>
            <th className="p-2 font-semibold border border-gray-600">Összeg</th>
            </tr>
        </thead>
        <tbody>
            <tr className="bg-gray-700">
                <td className="p-2 font-semibold border border-gray-600">Aranyba fektetve a befizetésből</td>
                <td className="p-2 border border-gray-600">{summarizationData.deduction.gold.toString()} USD</td>
            </tr>
            <tr className="bg-gray-700">
                <td className="p-2 font-semibold border border-gray-600">Energiába fektetve a befizetésből</td>
                <td className="p-2 border border-gray-600">{summarizationData.deduction.mining.toString()} USD</td>
            </tr>
            <tr className="bg-gray-700">
                <td className="p-2 font-semibold border border-gray-600">Altcoinba fektetve a befizetésből</td>
                <td className="p-2 border border-gray-600">{summarizationData.deduction.alt.toString()} USD</td>
            </tr>

            <tr className="bg-gray-700">
                <td className="p-2 font-semibold border border-gray-600">Aranyba fektetve a realizálásokból</td>
                <td className="p-2 border border-gray-600">{summarizationData.totalAttachments.gold.toString()} USD</td>
            </tr>
            <tr className="bg-gray-700">
                <td className="p-2 font-semibold border border-gray-600">Altcoinba fektetve a realizálásokból</td>
                <td className="p-2 border border-gray-600">{summarizationData.totalAttachments.altcoin.toString()} USD</td>
            </tr>
            <tr className="bg-gray-700">
                <td className="p-2 font-semibold border border-gray-600">Arany (uncia összesen)</td>
                <td className="p-2 border border-gray-600">{summarizationData.totalAttachments.ounce.toString()} db</td>
            </tr>
            <tr className="bg-gray-700">
                <td className="p-2 font-semibold border border-gray-600">Energiára elvonva a realizálásokból</td>
                <td className="p-2 border border-gray-600">{summarizationData.totalAttachments.mining.toString()} USD</td>
            </tr>
        </tbody>
        </table>
    </div>
    )}

    {summarizationData && (
    <>
    <div className="mt-6 p-4 bg-gray-800 rounded-lg text-white">
        <h3 className="text-lg font-semibold text-center mb-3">Összegzés</h3>

        <table className="w-full border-collapse border border-gray-500 text-center">
        <thead>
            <tr className="bg-gray-700">
            <th className="p-2 font-semibold border border-gray-600">Típus</th>
            <th className="p-2 font-semibold border border-gray-600">Kezdeti</th>
            <th className="p-2 font-semibold border border-gray-600">Aktuális</th>
            </tr>
        </thead>
        <tbody>
            <tr className="bg-gray-700">
                <td className="p-2 font-semibold border border-gray-600">Coinok összértéke</td>
                <td className="p-2 border border-gray-600">{summarizationData.todaysPrice.previous.toString()} USD</td>
                <td className="p-2 border border-gray-600">{summarizationData.todaysPrice.current.toString()} USD</td>
            </tr>
            <tr className="bg-gray-700">
                <td className="p-2 font-semibold border border-gray-600">Likviditások összértéke</td>
                <td className="p-2 border border-gray-600">{summarizationData.totalLiquidity.previous.toString()} USD</td>
                <td className="p-2 border border-gray-600">{summarizationData.totalLiquidity.current.toString()} USD</td>
            </tr>
            <tr className="bg-gray-700">
                <td className="p-2 font-semibold border border-gray-600">Portfolio összértéke</td>
                <td className="p-2 border border-gray-600">{summarizationData.totalValue.previous.toString()} USD</td>
                <td className="p-2 border border-gray-600">{summarizationData.totalValue.current.toString()} USD</td>
            </tr>
        </tbody>
        </table>
    </div>

    <div className="mt-6 p-4 bg-gray-800 rounded-lg text-white">
        <h3 className="text-lg font-semibold text-center mb-3">Százalékos változás</h3>
        <p className={`text-2xl font-semibold text-center
            ${parseFloat(summarizationData.rate.toString()) >= 0 ? 'text-green-500' : 'text-error'}`}>{summarizationData.rate.toString()}%</p>
    </div>
    </>
    )}

    {pieChartData && (

    <CustomPieChart title={"Kezdeti eloszlás"} data={pieChartData} />

    )}
    

    </div>
  );
};
export default HumanNewSimulation;
