import React from "react";
import { responseHero } from "../utils/axiosInterceptor";
import { Group } from "../models/group.model";
import { useSelector, useDispatch } from "react-redux";
import { selectMenuStates, set_daisy_menu } from "../store/reducers/menuSlice";

type GroupsSubMenuProps = {
    system_id: number;
    system_id2?: number;
    connectionsFlag?: boolean;
    toggleSidebar?: () => void;
    isSidebarOpen?: boolean;
};

export default function GroupsSubMenu({ system_id, system_id2, connectionsFlag, toggleSidebar, isSidebarOpen }: GroupsSubMenuProps) {
    const [groups, setGroups] = React.useState<Group[]>([]);
    const dispatch = useDispatch();
    const menu = useSelector(selectMenuStates);

    React.useEffect(() => {
        const getGroups = async () => {
            try {
                const response = await responseHero.get(`${process.env.REACT_APP_API_URL}/groups/${connectionsFlag}`);
                const filteredGroups = response.data.filter(
                    (group: Group) => 
                        (group.system_id === system_id || group.system_id === system_id2 || group.connected) && group.running === true
                );
                setGroups(filteredGroups);

                if(filteredGroups.length > 0) {
                    dispatch(set_daisy_menu(filteredGroups[0]));
                }
            } catch (error: any) {
                console.error(error.response?.data);
            }
        };
        getGroups();
    }, [system_id, system_id2, connectionsFlag]);

    if (groups.length === 0) {
        return null;
    }

    return (
      <div className="h-screen bg-gray-900 text-white p-4 border-l border-gray-700 overflow-auto relative">
        {/* Sidebar visszanyitó gomb (Csak ha a Sidebar zárva van) */}
        
        <h1 className="text-lg font-semibold mb-5">Csoportok</h1>
        <ul className="space-y-2">
          {groups.map((group) => (
            <li key={group.group_id}>
              <button
                onClick={() => dispatch(set_daisy_menu(group))}
                className={`w-full text-left px-2 py-1 rounded ${
                  menu.daisyMenu?.group_name === group.group_name ? "bg-cream text-dark-blue" : "hover:bg-gray-700"
                }`}
              >
                {group.group_name}
              </button>
            </li>
          ))}
        </ul>
      </div>
    );
}
