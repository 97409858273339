import React, { Fragment } from "react";
import { getDaisySumInfoByUserGroup } from "../../api/getDaisySumInfoByUserGroup";
import { useSelector } from "react-redux";
import { selectMenuStates } from "../../store/reducers/menuSlice";
import { GroupSummarization, UserEntity } from "../../models/types/DaisySummarize";
import EditDaisyExitDetailsModal from "../../components/daisy/modals/EditDaisyExitDetailsModal";
import { DaisyClosure } from "../../models/daisyclosure.model";
import { getDaisyClosureData } from "../../api/getDaisyClosureData";
import { currencyFormatter } from "../../utils/currencyFormatter";

export default function DaisyPayoutManager() {

    const menu = useSelector(selectMenuStates);

    const [users, setUsers] = React.useState<UserEntity[]>([]);
    const [total, setTotal] = React.useState<GroupSummarization>();

    const [editedEntity, setEditedEntity] = React.useState<DaisyClosure[]>([]);
    const [daisyClosureRecord, setDaisyClosureRecord] = React.useState<DaisyClosure>();
    const [selectedEntity, setSelectedEntity] = React.useState<UserEntity>();

    const [loading, setLoading] = React.useState<boolean>(false);
    const [showModal, setShowModal] = React.useState<boolean>(false);
    const [stateChange, setStateChange] = React.useState<boolean>(false);

    React.useEffect(() => {
        if(!menu.daisyMenu) return;
        setLoading(true);
        getDaisySumInfoByUserGroup(menu?.daisyMenu?.group_id).then((response) => {
            setLoading(false);
            setUsers(response.users)
            setTotal(response.total)
        }).catch((error) => {
            setLoading(false);
            console.error(error)
        });

    }, [menu.daisyMenu, stateChange])

    React.useEffect(() => {
        if(!menu.daisyMenu || users?.length === 0) return;

        getDaisyClosureData(menu?.daisyMenu?.group_id).then((response) => {
            setEditedEntity(response)
        }).catch((error) => console.error(error));
    }, [users])

    const closeModal = () => {
        setShowModal(false);
    }
    const openModal = (data: UserEntity, daisyClosureRecord: DaisyClosure | undefined) => {
        setSelectedEntity(data);
        setDaisyClosureRecord(daisyClosureRecord)
        setShowModal(true);
    }

    const handleStateChange = () => setStateChange(!stateChange);

    return (
        <>  
            {selectedEntity && (
                <EditDaisyExitDetailsModal daisyClosureRecord={daisyClosureRecord} title={"Zárási adatok módosítása"} group={menu?.daisyMenu} showModal={showModal} closeModal={closeModal} handleStateChange={handleStateChange} record={selectedEntity}/>
            )}
            
        
            <div className="m-5 p-3">
                {loading ?
                    <p className="text-xl text-center text-dark-blue animate-pulse">Kérlek várj még az adatok lekérdezésre kerülnek...</p>
                :
                    <> 
                        
                        {(total && users.length > 1) && (
                            <SummarizationPanel data={total}/>
                        )}
                        
                        {users ? users.map((data, index) => (
                            <Table data={data} openModal={openModal} editedEntity={editedEntity}/>
                        )) : <p className="text-center font-semibold">Probléma adódott a lekéréssel, lehetséges hogy valaki hiányzik a befizetésnyilvántartóból a csoportban. </p>}
                    </>
                }
            </div>
        </>
    );

}

type SummarizationPanelProps = {
    data: GroupSummarization;
}

const SummarizationPanel = ({ data }: SummarizationPanelProps) => {
    const menu = useSelector(selectMenuStates);

    return (
        <div className="mt-2 mb-2 px-2 py-2 border rounded bg-white shadow-lg shadow-gray-400 mb-5">
            <h1 className="text-xl font-semibold text-center py-4">{menu?.daisyMenu?.group_name} csoport összesítő</h1>
            <div className="flex flex-row space-x-4">
                {/* Block 1 */}
                <div className="rounded bg-gray-200 px-2 py-2 w-full">
                    <p className="text-md text-dark-blue flex justify-between">
                        Összesen befizetett USD:
                        <span className="font-semibold">{currencyFormatter(data.totalInvested.value_usd, 'USD')}</span>
                    </p>
                    <p className="text-md text-dark-blue flex justify-between">
                        Összesen befizetett EUR:
                        <span className="font-semibold">{currencyFormatter(data.totalInvested.value_eur, 'EUR')}</span>
                    </p>
                    <p className="text-md text-dark-blue flex justify-between mt-2">
                        Összesen befizetett (Crypto):
                        <span className="font-semibold">{currencyFormatter(data.totalDeposit.in_crypto, 'USD')}</span>
                    </p>
                    <p className="text-md text-dark-blue flex justify-between">
                        Összesen befizetett (Forex):
                        <span className="font-semibold">{currencyFormatter(data.totalDeposit.in_forex, 'USD')}</span>
                    </p>
                    <p className="text-md text-dark-blue flex justify-between mt-2">
                        Összesen a kereskedésbe került (Crypto):
                        <span className="font-semibold">{currencyFormatter(data.totalInTrade.in_crypto, 'USD')}</span>
                    </p>
                    <p className="text-md text-dark-blue flex justify-between">
                        Összesen a kereskedésbe került (Forex):
                        <span className="font-semibold">{currencyFormatter(data.totalInTrade.in_forex, 'USD')}</span>
                    </p>
                    <p className="text-md text-dark-blue flex justify-between">
                        Összesen a kereskedésbe került (USD):
                        <span className="font-semibold">{currencyFormatter(data.totalInTrade.in_usd, 'USD')}</span>
                    </p>
                    <p className="text-md text-dark-blue flex justify-between">
                        Összesen a kereskedésbe került (EUR):
                        <span className="font-semibold">{currencyFormatter(data.totalInTrade.in_eur, 'EUR')}</span>
                    </p>
                    <p className="text-md text-dark-blue flex justify-between mt-2 mb-2">
                        Összes különbözet USD:
                        <span className="font-semibold">{currencyFormatter(data.totalDifference.value_usd, 'USD')}</span>
                    </p>
                </div>

                {/* Block 2 */}
                <div className="rounded bg-gray-200 px-2 py-2 w-full">
                    <p className="text-md text-dark-blue flex justify-between">
                        Összesen eddig kikért USD:
                        <span className="font-semibold">{currencyFormatter(data.totalAssigned.value_usd, 'USD')}</span>
                    </p>
                    <p className="text-md text-dark-blue flex justify-between">
                        Összesen még kikérhető USD:
                        <span className="font-semibold">{currencyFormatter(data.totalRequestable.value_usd, 'USD')}</span>
                    </p>
                    <p className="text-md text-dark-blue flex justify-between">
                        Kereskedésből össz kifizetés USD:
                        <span className="font-semibold">{currencyFormatter(data.totalProfit.value_usd, 'USD')}</span>
                    </p>
                    <p className="text-md text-dark-blue flex justify-between">
                        Százalékban kifejezve:
                        <span className="font-semibold text-emerald-600">{data.totalRate}%</span>
                    </p>
                </div>
                {/* Block 3 */}
                <div className="rounded bg-gray-200 px-2 py-2 w-full">
                    <p className="text-md text-dark-blue flex justify-between">
                        Összesen fejlesztésre elvont összeg USD:
                        <span className="font-semibold">{currencyFormatter(data.totalInUpdate.value_usd, 'USD')}</span>
                    </p>
                    <p className="text-md text-dark-blue flex justify-between">
                        Összes részvény darabszám a csoportban:
                        <span className="font-semibold">{data.totalShareAmount} db</span>
                    </p>
                    <p className="text-md text-dark-blue flex justify-between">
                        Törzsrészvény bekerülési költsége/db
                        <span className="font-semibold">{currencyFormatter(data.totalSharePerUpdatePrice, 'USD')}</span>
                    </p>
                </div>
                {/* Block 4 */}
                <div className="rounded bg-gray-200 px-2 py-2 w-full">
                
                    <p className="text-md text-dark-blue flex justify-between">
                        Összesen a kereskedésből kifizetve USD:
                        <span className="font-semibold">{currencyFormatter(data.totalAllocation, 'USD')}</span>
                    </p>

                    <p className="text-md text-dark-blue flex justify-between">
                        Ügyfélnek már kifizetett jutalék USD:
                        <span className="font-semibold">{currencyFormatter(data.totalSent.to_client, 'USD')}</span>
                    </p>
                    <p className="text-md text-dark-blue flex justify-between">
                        A cégnek már kiutalva USD:
                        <span className="font-semibold">{currencyFormatter(data.totalSent.to_company, 'USD')}</span>
                    </p>

                    <p className="text-md text-dark-blue flex justify-between mt-5">
                        Ügyfelek megtérüléséhez szükséges (EUR):
                        <span className="font-semibold">{currencyFormatter(data.totalDifferenceBetweenInvestmentAndPaid?.value_eur, 'EUR')}</span>
                    </p>
                    <p className="text-md text-dark-blue flex justify-between">
                        Ügyfelek megtérüléséhez szükséges (USD):
                        <span className="font-semibold">{currencyFormatter(data.totalDifferenceBetweenInvestmentAndPaid?.value_usd, 'USD')}</span>
                    </p>

                    <p className="text-md text-dark-blue flex justify-between mt-2">
                        Kereskedés megtérüléséhez szükséges (USD): 
                        <span className="font-semibold">{currencyFormatter(data.totalTradeSentDifference.value_usd, 'USD')}</span>
                    </p>
                    <p className="text-md text-dark-blue flex justify-between">
                        Kereskedés megtérüléséhez szükséges (EUR): 
                        <span className="font-semibold">{currencyFormatter(data.totalTradeSentDifference.value_eur, 'EUR')}</span>
                    </p>
                </div>
            </div>

            {/* Block 5 */}
            <div className="border-b-2 border-dark-blue mt-2 mb-2 w-full"></div>

            <div className="rounded bg-gray-300 px-2 py-2 w-2/4">
                <p className="text-md text-dark-blue flex justify-between">
                    Ügyfélnek fizetésre váró jutalék (Záráskor) (USD): 
                    <span className="font-semibold text-orange-400">{currencyFormatter(data.totalStillToBePaid.client.at_closure.value_usd, 'USD')}</span>
                </p>
                <p className="text-md text-dark-blue flex justify-between mb-5">
                    Ügyfélnek fizetésre váró jutalék (Ma) (USD): 
                    <span className="font-semibold text-orange-400">{currencyFormatter(data.totalStillToBePaid.client.at_today.value_usd, 'USD')}</span>
                </p>

                <p className="text-md text-dark-blue flex justify-between">
                    Cégnek fizetésre váró jutalék (Záráskor) (USD): 
                    <span className="font-semibold text-orange-400">{currencyFormatter(data.totalStillToBePaid.company.at_closure.value_usd, 'USD')}</span>
                </p>
                <p className="text-md text-dark-blue flex justify-between mb-5">
                    Cégnek fizetésre váró jutalék (Ma) (USD): 
                    <span className="font-semibold text-orange-400">{currencyFormatter(data.totalStillToBePaid.company.at_today.value_usd, 'USD')}</span>
                </p>
                
                <p className="text-md text-dark-blue flex justify-between">
                    Ügyfél: Különbség a mai - záráskori (USD): 
                    <span className="font-semibold text-orange-800">{currencyFormatter(data.totalStillToBePaidDifference.client.value_usd, 'USD')}</span>
                </p>
                <p className="text-md text-dark-blue flex justify-between mb-5">
                    Cég: Különbség a mai - záráskori (USD): 
                    <span className="font-semibold text-orange-800">{currencyFormatter(data.totalStillToBePaidDifference.company.value_usd, 'USD')}</span>
                </p>

                <p className="text-md text-dark-blue flex justify-between">
                    Ügyfélnek fizetésre váró jutalék (EUR): 
                    <span className="font-semibold text-emerald-600">{currencyFormatter(data.totalStillToBePaid.client.at_closure.value_eur, 'EUR')}</span>
                </p>
                <p className="text-md text-dark-blue flex justify-between">
                    Cégnek fizetésre váró jutalék (EUR): 
                    <span className="font-semibold text-emerald-600">{currencyFormatter(data.totalStillToBePaid.company.at_closure.value_eur, 'EUR')}</span>
                </p>
            </div>
        </div>
    );
};


type DaisySummarizeProps = {
    data: UserEntity;
    openModal : (data: UserEntity, daisyClosureRecord: DaisyClosure | undefined) => void;
    editedEntity: DaisyClosure[];
}

const Table = ({ data, openModal, editedEntity }: DaisySummarizeProps) => {
    const searchEntity = editedEntity.find((entity) => entity.user_id === data.metaData.user_id);

    return (
        <div className="px-2 py-2 border rounded bg-white shadow-lg shadow-gray-400 mb-5">
            <p className="text-lg text-dark-blue">
                Név: 
                <span className="font-semibold">{data.name}</span>
            </p>
            <p className="text-sm text-dark-blue">
                Csoport: 
                <span className="font-semibold">{data.group_name}</span>
            </p>

            <div className="border-2 px-2 py-2 mt-2">
                <div className="flex flex-row space-x-4">
                    {/* Block 1 */}
                    <div className="rounded bg-gray-200 px-2 py-2 w-full">
                        <p className="text-md text-dark-blue flex justify-between">
                            Befizetett összesen (USD): 
                            <span className="font-semibold">{currencyFormatter(data.invested.value_usd, 'USD')}</span>
                        </p>
                        <p className="text-md text-dark-blue flex justify-between py-2">
                            Befizetett összesen (EUR): 
                            <span className="font-semibold text-emerald-600">{currencyFormatter(data.invested.value_eur, 'EUR')}</span>
                        </p>
                        <p className="text-md text-dark-blue flex justify-between">
                            Befizetett FOREX: 
                            <span className="font-semibold">{currencyFormatter(data.in_forex.value_usd, 'USD')}</span>
                        </p>
                        <p className="text-md text-dark-blue flex justify-between py-2">
                            Befizetett CRYPTO: 
                            <span className="font-semibold">{currencyFormatter(data.in_crypto.value_usd, 'USD')}</span>
                        </p>

                        <p className="text-md text-dark-blue flex justify-between">
                            A kereskedésbe került FOREX: 
                            <span className="font-semibold">{currencyFormatter(data.in_trade_forex.value_usd, 'USD')}</span>
                        </p>
                        <p className="text-md text-dark-blue flex justify-between">
                            A kereskedésbe került CRYPTO: 
                            <span className="font-semibold">{currencyFormatter(data.in_trade_crypto.value_usd, 'USD')}</span>
                        </p>
                        <p className="text-md text-dark-blue flex justify-between">
                            Összesen a kereskedésbe került (USD):
                            <span className="font-semibold">{currencyFormatter(data.in_trade_total.value_usd, 'USD')}</span>
                        </p>
                        <p className="text-md text-dark-blue flex justify-between mb-2">
                            Összesen a kereskedésbe került (EUR):
                            <span className="font-semibold">{currencyFormatter(data.in_trade_total.value_eur, 'EUR')}</span>
                        </p>
                        <p className="text-md text-dark-blue flex justify-between">
                            Különbözet:
                            <span className="font-semibold">{currencyFormatter(data.difference.value_usd, 'USD')}</span>
                        </p>
                    </div>

                    {/* Block 2 */}
                    <div className="rounded bg-gray-200 px-2 py-2 w-full">
                        <p className="text-md text-dark-blue flex justify-between">
                            Kikért összeg: 
                            <span className="font-semibold">{currencyFormatter(data.assigned.value_usd, 'USD')}</span>
                        </p>
                        <p className="text-md text-dark-blue flex justify-between">
                            Még kikérhető összeg: 
                            <span className="font-semibold">{currencyFormatter(data.requestable.value_usd, 'USD')}</span>
                        </p>
                        <p className="text-md text-dark-blue flex justify-between py-2 ">
                            Még kikérhető összeg (EUR): 
                            <span className="font-semibold text-emerald-600">{currencyFormatter(data.requestable.value_eur, 'EUR')}</span>
                        </p>

                        <p className="text-md text-dark-blue flex justify-between">
                            Kereskedésből össz kifizetés: 
                            <span className="font-semibold">{currencyFormatter(data.total_profit.value_usd, 'USD')}</span>
                        </p>
                        <p className="text-md text-dark-blue flex justify-between">
                            Kereskedésből össz kifizetés (EUR): 
                            <span className="font-semibold text-emerald-600">{currencyFormatter(data.total_profit.value_eur, 'EUR')}</span>
                        </p>

                        <p className="text-md text-dark-blue flex justify-between">
                            Százalékban kifejezve:
                            <span className="font-semibold text-emerald-600">{data.rate}%</span>
                        </p>
                    </div>

                    {/* Block 3 */}
                    <div className="rounded bg-gray-200 px-2 py-2 w-full">
                        <p className="text-md text-dark-blue flex justify-between">
                            Fejlesztésre elvont összeg: 
                            <span className="font-semibold">{currencyFormatter(data.for_update.value_usd, 'USD')}</span>
                        </p>
                        <p className="text-md text-dark-blue flex justify-between py-2">
                            Fejlesztésre elvont összeg (EUR): 
                            <span className="font-semibold text-emerald-600">{currencyFormatter(data.for_update.value_eur, 'EUR')}</span>
                        </p>

                        <p className="text-md text-dark-blue flex justify-between">
                            Kapott törzsrészvény darabszám: 
                            <span className="font-semibold">{data.share_amount} db</span>
                        </p>
                        <p className="text-md text-dark-blue flex justify-between">
                            Törzsrészvény bekerülési költsége/db
                            <span className="font-semibold">{currencyFormatter(data.share_price.value_usd, 'USD')}</span>
                        </p>
                    </div>

                    {/* Block 4 */}
                    <div className="rounded bg-gray-200 px-2 py-2 w-full">
                        <p className="text-md text-dark-blue flex justify-between mb-2">
                            Összesen kereskedésből kifizetve (USD):
                            <span className="font-semibold">{currencyFormatter(data.total_allocation, 'USD')}</span>
                        </p>
                        <p className="text-md text-dark-blue flex justify-between">
                            Ügyfélnek már kifizetett jutalék (USD): 
                            <span className="font-semibold">{currencyFormatter(data.sent_to_client.value_usd, 'USD')}</span>
                        </p>

                        <p className="text-md text-dark-blue flex justify-between">
                            Cégnek már kifizetett jutalék (USD):
                            <span className="font-semibold">{currencyFormatter(data.sent_to_company.value_usd, 'USD')}</span>
                        </p> 

                        <p className="text-md text-dark-blue flex justify-between mt-5">
                            Ügyfél megtérüléséhez szükséges (EUR): 
                            <span className="font-semibold text-emerald-600">{currencyFormatter(data.differenceBetweenInvestmentAndPaid?.value_eur, 'EUR')}</span>
                        </p>
                        <p className="text-md text-dark-blue flex justify-between">
                            Ügyfél megtérüléséhez szükséges (USD): 
                            <span className="font-semibold text-emerald-600">{currencyFormatter(data.differenceBetweenInvestmentAndPaid?.value_usd, 'USD')}</span>
                        </p>

                        <p className="text-md text-dark-blue flex justify-between mt-2">
                            Kereskedés megtérüléséhez szükséges (USD): 
                            <span className="font-semibold">{currencyFormatter(data.tradeSentDifference.value_usd, 'USD')}</span>
                        </p>
                        <p className="text-md text-dark-blue flex justify-between">
                            Kereskedés megtérüléséhez szükséges (EUR): 
                            <span className="font-semibold">{currencyFormatter(data.tradeSentDifference.value_eur, 'EUR')}</span>
                        </p>
                    </div>
                </div>
                {/* Block 5 */}
                <div className="border-b-2 border-dark-blue mt-2 mb-2 w-full"></div>

                <div className="rounded bg-gray-300 px-2 py-2 w-1/4">
                    <p className="text-md text-dark-blue flex justify-between">
                        Ügyfélnek fizetésre váró jutalék (Záráskor) (USD): 
                        <span className="font-semibold text-orange-400">{currencyFormatter(data.still_to_be_paid.client.at_closure.value_usd, 'USD')}</span>
                    </p>
                    <p className="text-md text-dark-blue flex justify-between mb-5">
                        Ügyfélnek fizetésre váró jutalék (Ma) (USD): 
                        <span className="font-semibold text-orange-400">{currencyFormatter(data.still_to_be_paid.client.at_today.value_usd, 'USD')}</span>
                    </p>

                    <p className="text-md text-dark-blue flex justify-between">
                        Cégnek fizetésre váró jutalék (Záráskor) (USD): 
                        <span className="font-semibold text-orange-400">{currencyFormatter(data.still_to_be_paid.company.at_closure.value_usd, 'USD')}</span>
                    </p>
                    <p className="text-md text-dark-blue flex justify-between mb-5">
                        Cégnek fizetésre váró jutalék (Ma) (USD): 
                        <span className="font-semibold text-orange-400">{currencyFormatter(data.still_to_be_paid.company.at_today.value_usd, 'USD')}</span>
                    </p>
                    
                    <p className="text-md text-dark-blue flex justify-between">
                        Ügyfél: Különbség a mai - záráskori (USD): 
                        <span className="font-semibold text-orange-800">{currencyFormatter(data.still_to_be_paid_difference.client.value_usd, 'USD')}</span>
                    </p>
                    <p className="text-md text-dark-blue flex justify-between mb-5">
                        Cég: Különbség a mai - záráskori (USD): 
                        <span className="font-semibold text-orange-800">{currencyFormatter(data.still_to_be_paid_difference.company.value_usd, 'USD')}</span>
                    </p>

                    <p className="text-md text-dark-blue flex justify-between">
                        Ügyfélnek fizetésre váró jutalék (EUR): 
                        <span className="font-semibold text-emerald-600">{currencyFormatter(data.still_to_be_paid.client.at_closure.value_eur, 'EUR')}</span>
                    </p>
                    <p className="text-md text-dark-blue flex justify-between">
                        Cégnek fizetésre váró jutalék (EUR): 
                        <span className="font-semibold text-emerald-600">{currencyFormatter(data.still_to_be_paid.company.at_closure.value_eur, 'EUR')}</span>
                    </p>
                </div>
            </div>
            {searchEntity && (
                <div className="border-2 px-2 py-2 mt-2">
                    <div className="flex flex-row space-x-4">
                        {/* Block 1 */}
                        <div className="rounded bg-gray-200 px-2 py-2 w-full">
                            <h2 className="text-lg text-center py-2">Új adatok listája</h2>
                            <p className="text-md text-dark-blue flex justify-between">
                                Befizetett összesen (USD): 
                                <span className="font-semibold">{currencyFormatter(searchEntity.invested_usd, 'USD')}</span>
                            </p>
                            <p className="text-md text-dark-blue flex justify-between py-2">
                                Befizetett összesen (EUR): 
                                <span className="font-semibold">{currencyFormatter(searchEntity.invested_eur, 'EUR')}</span>
                            </p>
                            <p className="text-md text-dark-blue flex justify-between">
                                Tőkeviszonyszám (USD): 
                                <span className="font-semibold">{currencyFormatter(searchEntity.capital_ratio, 'USD')}</span>
                            </p>
                            <p className="text-md text-dark-blue flex justify-between">
                                Részvény darabszám (db): 
                                <span className="font-semibold">{searchEntity.share}db</span>
                            </p>
                        </div>

                        {/* Block 2 */}
                        {searchEntity.compensations.length > 0 && (
                            <div className="rounded bg-gray-200 px-2 py-2 w-full">
                                <h2 className="text-lg text-center py-2">Human D.C Kompenzációk listája</h2>
                                {searchEntity.compensations.map((comp, index) => (
                                    <Fragment>
                                        <p className="text-md text-dark-blue flex justify-between">
                                            Dátum:
                                            <span className="font-semibold">{comp.date?.toString().split('T')[0]}</span>
                                        </p>
                                        <p className="text-md text-dark-blue flex justify-between py-2">
                                            Összeg (USD): 
                                            <span className="font-semibold">{currencyFormatter(comp.amount, 'USD')}</span>
                                        </p>
                                    </Fragment>
                                ))}
                            </div>
                        )}
                    </div>
                </div>
            )}
            <div className="flex items-center justify-center">
                <button onClick={() => openModal(data, searchEntity)}className="mt-2 rounded text-white opacity-80 shadow-md hover:opacity-100 px-2 py-1 bg-success w-full">
                    Kézi módosítás 
                </button>
            </div>
        </div>    
    );
};



