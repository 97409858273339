import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { selectUser, remove } from "../store/reducers/userSlice";
import { decodeJwtToken } from '../utils/verifyJwt';
import { useNavigate } from 'react-router';
import { responseHero } from '../utils/axiosInterceptor';
import { Spinner } from '../utils/Spinner';

interface Statistics {
    number_of_projects: number;
    number_of_users: number;
    number_of_groups: number;
    number_of_active_groups: number;
    number_of_envelopes: number;
    number_of_templates: number,
    number_of_orgs: number;
    amount_of_investments: number;
    number_of_deposit_registers: number;
}

interface MarketValues {
    name: string;
    price: string;
}

interface MarketStats {
    marketStats: MarketValues[]
}

const MarketStatistics = ({marketStats} : MarketStats) => {

    if(marketStats.length !== 0) {
        return (
            <>
                {marketStats?.map((memoizedMarketStat) => (
                    <div className="flex flex-col items-center justify-center">
                        <dt className="mb-2 text-3xl font-extrabold text-light-brown">{memoizedMarketStat?.price}</dt>
                        <dd className="text-gray-400 text-white">{memoizedMarketStat?.name} árfolyam</dd>
                    </div>
                ))}
            </>
        )
    } else {
        return  (
            <>
                <Spinner width={8} height={8} show={true}/><Spinner width={8} height={8} show={true}/><Spinner width={8} height={8} show={true}/>
            </>
        )
    }
}

export default function Home () {
    const user = useSelector(selectUser); // selector for redux store
    const dispatch = useDispatch(); // dispatch for redux store

    const [tab, setTab] = React.useState<number>(1)

    const [stats, setStats] = React.useState<Statistics>()

    const navigate = useNavigate();
    const lastViewedPage = localStorage.getItem("lastViewedPage")

    const currentDate = new Date();
    const currentHour = currentDate.getHours();

    let welcomeMessage;

    if (currentHour < 12) {
        welcomeMessage = "Jó reggelt, ";
    } else if (currentHour < 18) {
        welcomeMessage = "Jó napot, ";
    } else {
        welcomeMessage = "Jó estét, ";
    }


    React.useEffect(() => {
        const storedUser = localStorage.getItem('user');
        const storedAccessToken = storedUser ? JSON.parse(storedUser).accessToken : '';
        const result = decodeJwtToken(storedAccessToken);

        if(!result) return;

        if(result.isExpired) {
            dispatch(remove()); // log out user
        }
    });

    const getStats = React.useCallback(async () => {
        try {
          const response = await responseHero.get(
            `${process.env.REACT_APP_API_URL}/stats`
          );
    
          setStats(response.data)

        } catch (error: any) {
          console.error(error.response.data);
        }
      }, [stats]);
    
      /* Effects */
      React.useEffect(() => {
        getStats();
      }, []);
    
    return (
        <>
            <div className="m-20">
                <h1 className='text-center text-3xl font-semibold'>Human D.C - Admin Panel.</h1>
                <p className='text-center px-2 py-2 text-lg'>Statisztikai adatok feltöltés alatt...</p>
            </div>
        </>
    )
}