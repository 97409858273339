import Decimal from "decimal.js";
import React from "react";
import { Pie } from "react-chartjs-2";
import { Chart, registerables } from "chart.js";
import ChartDataLabels, { Context } from "chartjs-plugin-datalabels";

// Register core components globally
Chart.register(...registerables);

interface PieChartProps {
  title: string;
  data: { name: string; value: number | Decimal }[];
}

const SimplePieChart: React.FC<PieChartProps> = ({ title, data }) => {
  const finalData = data
  .filter((item) => parseFloat(item.value.toString()) > 0);

  const chartData = {
    labels: finalData
            .map((item) => item.name),
    datasets: [
      {
        data: finalData
        .map((item) => new Decimal(item.value).toNumber()),
        backgroundColor: finalData.map(() => "#2C2C2C"), // Elegant dark gray
        hoverBackgroundColor: finalData.map(() => "#444444"), // Slightly lighter on hover
        borderWidth: 1,
        borderColor: finalData.map(() => "#E8D788"), // Soft yellow border
        hoverBorderColor: finalData.map(() => "#FFD700"), // Bright golden border on hover
      },
    ],
  };

  const options = {
    
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
      datalabels: {
        color: "#E8D788",
        font: {
          weight: "bold" as "bold",
          size: 14,
        },
        formatter: (value: number, context: Context) => {
          return context.chart.data.labels?.[context.dataIndex] || "";
        },
      },
    },
  };

  
  return (
    <div className="w-max">
      <h1 className="text-lg text-center font-bold text-gray-200 mb-2">
        {title}
      </h1>
      {/* ✅ Fix: Explicitly cast ChartDataLabels to Plugin<"pie"> */}
      <Pie data={chartData} options={options} plugins={[ChartDataLabels as any]} />
    </div>
  );
};

export default SimplePieChart;
