import { Template } from "../models/templates.model";
import { responseHero } from "../utils/axiosInterceptor";

export const getActiveTemplates = (): Promise<Template[]> => {
    return new Promise<Template[]>((resolve, reject) => {
        responseHero.get(`${process.env.REACT_APP_API_URL}/atemplates`).then((response) => {
            resolve(response.data as Template[]);
        })
        .catch(error => {
            console.error(error);
            reject(error);
        });
    });
};