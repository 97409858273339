import { useState, useEffect } from "react";
import { User } from "../../../../models/user.model";
import { getUsers } from "../../../../api/getUsers";

type ModalProps = {
    title: string;
    showModal: boolean;
    closeModal: () => void;
    onUserSelect: (user: User) => void;
};

const UserSelectModal = ({ title, showModal, closeModal, onUserSelect }: ModalProps) => {
    const [users, setUsers] = useState<User[]>([]);
    const [filteredUsers, setFilteredUsers] = useState<User[]>([]);
    const [searchQuery, setSearchQuery] = useState<string>("");
    const [searchBy, setSearchBy] = useState<"name" | "email">("name");
    const [selectedUser, setSelectedUser] = useState<User | null>(null);

    useEffect(() => {
        const fetchUsers = async () => {
            try {
                const response = await getUsers();
                setUsers(response);
            } catch (error) {
                console.error("Error fetching users:", error);
            }
        };

        if (showModal) {
            fetchUsers();
        }
    }, [showModal]);

    useEffect(() => {
        if (searchQuery.trim() === "") {
            setFilteredUsers([]);
            return;
        }

        const filtered = users.filter(user =>
            user[searchBy].toLowerCase().includes(searchQuery.toLowerCase())
        );
        setFilteredUsers(filtered);
    }, [searchQuery, searchBy, users]);

    const handleSelectUser = (user: User) => {
        setSelectedUser(user);
    };

    const handleConfirmSelection = () => {
        if (selectedUser) {
            onUserSelect(selectedUser);
            closeModal();
        }
    };

    return (
        <>
            {showModal && (
                <>
                    <div className="flex justify-center items-center overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
                        <div className="relative w-full my-6 mx-auto max-w-2xl">
                            <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                                <div className="flex items-start justify-between p-5 border-b border-gray-300 rounded-t">
                                    <h3 className="text-xl font-semibold text-brown">
                                        {title}
                                    </h3>
                                    <button
                                        className="text-red-500 font-bold uppercase text-sm"
                                        onClick={closeModal}
                                    >
                                        X
                                    </button>
                                </div>

                                <div className="relative p-6 flex-auto">
                                    {/* Search Input */}
                                    <input
                                        type="text"
                                        value={searchQuery}
                                        onChange={(e) => setSearchQuery(e.target.value)}
                                        className="shadow border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                        placeholder={`Keresés ${searchBy === 'name' ? 'név' : 'email'} alapján`}
                                    /> 

                                    {/* Radio Buttons */}
                                    <div className="mt-3 flex justify-center space-x-4">
                                        <label className="inline-flex items-center">
                                            <input
                                                type="radio"
                                                name="searchBy"
                                                value="name"
                                                checked={searchBy === "name"}
                                                onChange={() => setSearchBy("name")}
                                                className="form-radio"
                                            />
                                            <span className="ml-2">Keresés név alapján</span>
                                        </label>

                                        <label className="inline-flex items-center">
                                            <input
                                                type="radio"
                                                name="searchBy"
                                                value="email"
                                                checked={searchBy === "email"}
                                                onChange={() => setSearchBy("email")}
                                                className="form-radio"
                                            />
                                            <span className="ml-2">Keresés email alapján</span>
                                        </label>
                                    </div>

                                    {/* Search Results */}
                                    <div className="mt-4 max-h-40 overflow-y-auto">
                                        {filteredUsers.length > 0 ? (
                                            filteredUsers.map((user) => (
                                                <div
                                                    key={user.user_id}
                                                    className="flex justify-between items-center p-2 border-b hover:bg-gray-100 cursor-pointer"
                                                >
                                                    <div>
                                                        <p className="text-sm font-semibold">{user.name}</p>
                                                        <p className="text-xs text-gray-500">{user.email}</p>
                                                    </div>
                                                    <button
                                                        className={`px-3 py-1 rounded text-sm ${
                                                            selectedUser?.user_id === user.user_id 
                                                                ? "bg-green-500 text-white" 
                                                                : "bg-blue-500 text-white hover:bg-blue-600"
                                                        }`}
                                                        onClick={() => handleSelectUser(user)}
                                                    >
                                                        {selectedUser?.user_id === user.user_id ? "Kiválasztva" : "Kiválaszt"}
                                                    </button>
                                                </div>
                                            ))
                                        ) : (
                                            <p className="text-sm text-gray-500 mt-2">
                                                Nem található a szűrési feltételeknek megfelelő Ügyfél.
                                            </p>
                                        )}
                                    </div>

                                </div>

                                {/* Modal Footer */}
                                <div className="flex items-center justify-end p-6 border-t border-gray-200 rounded-b">
                                    <button
                                        className="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none"
                                        type="button"
                                        onClick={closeModal}
                                    >
                                        Bezárom
                                    </button>
                                    <button
                                        className={`text-white px-6 py-2 rounded ${
                                            selectedUser ? "bg-green-500 hover:bg-green-600" : "bg-gray-400 cursor-not-allowed"
                                        }`}
                                        type="button"
                                        onClick={handleConfirmSelection}
                                        disabled={!selectedUser}
                                    >
                                        Tovább
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="fixed inset-0 bg-black bg-opacity-50 backdrop-filter backdrop-blur-sm"></div>
                </>
            )}
        </>
    );
};

export default UserSelectModal;
