import { ListElement } from "../models/simulation/CommonTypes";
import { BraveSimRes, HumanSimRes } from "../models/simulation/Responses";
import { responseHero } from "../utils/axiosInterceptor";

export const getBraveSimulationData = (depositRegisterId: number): Promise<BraveSimRes> => {
    return new Promise<BraveSimRes>((resolve, reject) => {
        if(!depositRegisterId) return;

        responseHero.get(`${process.env.REACT_APP_API_URL}/simb/${depositRegisterId}`).then((response) => {
            resolve(response.data as BraveSimRes);
        })
        .catch(error => {
            console.error(error);
            reject(error);
        });
    });
};

export const getHumanSimulationData = (depositRegisterId: number): Promise<HumanSimRes> => {
    return new Promise<HumanSimRes>((resolve, reject) => {
        if(!depositRegisterId) return;

        responseHero.get(`${process.env.REACT_APP_API_URL}/simh/${depositRegisterId}`).then((response) => {
            resolve(response.data as HumanSimRes);
        })
        .catch(error => {
            console.error(error);
            reject(error);
        });
    });
};

export const getList = (system_id: number, date: Date): Promise<ListElement[]> => {
    return new Promise<ListElement[]>((resolve, reject) => {
        if(!system_id && date) return;

        const reformatDate = date.toString().split('T')[0];

        responseHero.get(`${process.env.REACT_APP_API_URL}/simlist/${system_id}/${reformatDate}`).then((response) => {
            resolve(response.data as ListElement[]);
        })
        .catch(error => {
            console.error(error);
            reject(error);
        });
    });
};