import { useSelector } from "react-redux";
import ShowContractDetails from "../../components/contract/ShowContractDetails";
import { selectNavStates } from "../../store/reducers/navSlice";
import { Spinner } from "../../utils/Spinner";
import ContractTypeList from "../../components/contract/ContractTypeList";

export default function ContractsPage () {
    const nav = useSelector(selectNavStates)

    return (
        <div className="m-5 p-3">
            <ContractTypeList/>
            {nav.templates.length > 0 ? (
                <ShowContractDetails/>
            ) : (
                <Spinner show={true} width={12} height={12}/>
            )}
        </div>
    )
}
