import { UserGroups } from "../models/usergroups.model";
import { responseHero } from "../utils/axiosInterceptor";

export const getUsersInGroup = (group_id: number): Promise<UserGroups[]> => {
    return new Promise<UserGroups[]>((resolve, reject) => {
        if(!group_id) return;

        responseHero.get(`${process.env.REACT_APP_API_URL}/usersingroup/${group_id}`).then((response) => {
            resolve(response.data as UserGroups[]);
        })
        .catch(error => {
            console.error(error);
            reject(error);
        });
    });
};