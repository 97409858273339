import Decimal from "decimal.js";
import { BinanceRates } from "../binancerates.model";
import { AssetPurchase } from "../assetpurchase.model";
import { DepositRegister } from "../depositregister";
import { Asset } from "../assets.model";

// pre

type AssetPurchasesData = Pick<AssetPurchase, "assetId" | "quantity" | "amount" | "date" | "exchange_rate"> & {
    asset: Pick<Asset, "name" | "code" | "apiId">;
};

export type PreparedData = {
    depositRegisterData: Pick<DepositRegister, "id" | "amount_in_trade" | "liquidity" | "date" | "deduction_all" | "deduction_mining" | "deduction_physical_gold" | "deduction_alt" | "cost_deduction" | "fee"> | null;
    assetPurchasesData: AssetPurchasesData[];
    binanceRates: Map<number, Omit<BinanceRates, "id" | "asset">[]>;
}

// common

export const RecordType = {
    SIMPLE_TRANSACTION: 0,
    INVESTMENT_TRANSACTION: 1,
    FROM_GOLD: 2,
};

export type Transaction = {
    date: Date;
    amount: Decimal;
    quantity: Decimal;
    binanceRateRecord: Omit<BinanceRates, "id" | "asset" | "assetId">;
    recordType: number;
    executionPrice: Decimal;
};

export type ListElement = {
    date: Date;
    name: string;
    invested_amount_eur: number;
    totalValue: Decimal;
    rate: Decimal;
    payout_date: Date;
    payout_amount: number;
};






