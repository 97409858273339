import React, { useEffect, useState } from "react";
import { getUserDepositsData } from "../../../api/getUserDepositsData";
import { Systems } from "../../../enums/systems";
import { DepositRegister } from "../../../models/depositregister";
import { Envelope } from "../../../models/envelope.model";
import { toast } from "react-toastify";
import { responseHero } from "../../../utils/axiosInterceptor";
import { env } from "process";

type ModalProps = {
    title: string;
    showModal: boolean;
    closeModal: () => void;
    envelopeData: Envelope;
    refreshEnvelope: (updatedEnvelope: Envelope) => void;
};

const PairToDepositRegister = ({ title, showModal, closeModal, envelopeData, refreshEnvelope }: ModalProps) => {
    const [deposits, setDeposits] = useState<DepositRegister[]>([]);
    const [selectedDeposit, setSelectedDeposit] = useState<string | null>(null);

    useEffect(() => {
        if(envelopeData && envelopeData.depositRegisterId) {
            setSelectedDeposit(envelopeData.depositRegisterId.toString());
        }
    }, [envelopeData]);

    const pairToDeposit = async (selectedDeposit: string | null) => {
        try {
            const envelope = {
                depositRegisterId: selectedDeposit ? parseInt(selectedDeposit, 10) : null
            };

            const response = await responseHero.patch(`${process.env.REACT_APP_API_URL}/envelope/${envelopeData?.envelope_id}`, { envelope: envelope });
    
            toast.success(`Sikeresen változtattad a szerződés állapotát!`);
            
            refreshEnvelope(response.data);
            closeModal();
        } catch (error: any) {
            console.error(JSON.stringify(error.response.data.message, null, 2));
            toast.error(`Sikertelen módosítási kísérlet!`);
        }
    };

    useEffect(() => {
        const fetchUserDeposits = async () => {
            const humanDeposits = await getUserDepositsData(envelopeData.signer.user_id, Systems.HPM);
            const braveDeposits = await getUserDepositsData(envelopeData.signer.user_id, Systems.ALT);
            setDeposits([...humanDeposits, ...braveDeposits]);
        };

        fetchUserDeposits();
    }, [envelopeData]);

    const handleSelect = (event: React.ChangeEvent<HTMLSelectElement>) => {
        setSelectedDeposit(event.target.value);
    };

    return (
        <>
            {showModal && (
                <>
                    <div className="flex justify-center items-center overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
                        <div className="relative w-full my-6 mx-auto max-w-4xl">
                            <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                                <div className="flex items-start justify-between p-5 border-b border-solid border-gray-300 rounded-t">
                                    <h3 className="text-xl font-semibold">{title}</h3>
                                    
                                </div>
                                <div className="relative p-6 flex-auto">

                                    <div className="p-4 shadow-md mb-2">
                                        <p className="py-1 text-lg">Név: <span className="font-semibold">{envelopeData.signer.name}</span></p>
                                        <p className="py-1 text-lg">Összeg: <span className="font-semibold">{envelopeData.invested_amount} {envelopeData.currency}</span></p>
                                        <p className="py-1 text-lg">Dátum: <span className="font-semibold">{envelopeData.statusDateTime.toString().split('T')[0]}</span></p>
                                    </div>
                                    <form>
                                        <div className="flex flex-col p-2 rounded shadow-md">
                                            <label htmlFor="depositSelect" className="mb-2 font-semibold">
                                                Válassz egy befektetést:
                                            </label>
                                            <select
                                                id="depositSelect"
                                                className="border p-2 rounded"
                                                value={selectedDeposit ?? ""}
                                                onChange={handleSelect}
                                            >
                                                <option value="" disabled>
                                                    Válassz egyet...
                                                </option>
                                                {deposits.map((deposit) => (
                                                    <option key={deposit.id} value={deposit.id}>
                                                        {deposit.user.name} - {deposit.invested_amount_eur} EUR ({deposit.date.toString().split('T')[0]}) ({deposit.group.system_id === Systems.HPM ? "Human D.C." : "BRAVE"})
                                                    </option>
                                                ))}
                                            </select>
                                        </div>
                                    </form>
                                </div>
                                <div className="flex items-center justify-end p-6 border-t border-solid border-blueGray-200 rounded-b">
                                    <button
                                        className="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1"
                                        type="button"
                                        onClick={closeModal}
                                    >
                                        Mégse
                                    </button>
                                    <button
                                        className="text-error bg-gray-200 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1"
                                        type="button"
                                        onClick={() => pairToDeposit(null)}
                                    >
                                        Párosítás megszüntetése
                                    </button>
                                    <button
                                        className="text-cream bg-dark-blue font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1"
                                        type="button"
                                        onClick={() => pairToDeposit(selectedDeposit)}
                                    >
                                        Hozzáad
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="fixed inset-0 bg-black bg-opacity-50 backdrop-filter backdrop-blur-sm"></div>
                </>
            )}
        </>
    );
};

export default PairToDepositRegister;
