import { useState } from "react";
import Sidebar from "./Sidebar";
import GroupsSubMenu from "../components/GroupsSubMenu";
import { Outlet, useLocation, useNavigate } from "react-router-dom";

export const Layout = () => {
  const [isSidebarOpen, setSidebarOpen] = useState(true);
  const [activeSystem, setActiveSystem] = useState<number | null>(null);
  const location = useLocation()

  // Azok az oldalak, ahol a GroupsSubMenu megjelenik
  const showGroupMenuRoutes = [
    "/daisy",
    "/payouts",
    "/hpm",
    "/miningmachines",
    "/altcoin",
    "/leverage"
  ];
  const shouldShowGroupMenu = showGroupMenuRoutes.some(route => location.pathname.startsWith(route));

  const toggleMenus = () => {
    setSidebarOpen(!isSidebarOpen);
  };

  return (
    <div className="flex h-screen relative">
      {/* Menü gomb a bal felső sarokban, ha a Sidebar zárva van */}
      {!isSidebarOpen && (
        <button
          onClick={toggleMenus}
          className="fixed top-4 left-4 bg-cream text-dark-blue px-3 py-1 rounded-lg shadow-lg z-50"
        >
          ☰ Menü
        </button>
      )}

      {/* Sidebar + Nyitó gomb */}
      <div
        className={`fixed left-0 top-0 h-full bg-dark-blue text-white w-64 transition-transform duration-300 ease-in-out z-50 ${
          isSidebarOpen ? "translate-x-0" : "-translate-x-64"
        }`}
      >
        {/* Nyitó/Záró gomb a Sidebar tetején */}
        <button 
          onClick={toggleMenus} 
          className="m-2 bg-cream text-dark-blue px-3 py-1 rounded-lg shadow-lg z-50"
        >
          {isSidebarOpen ? "✖ Bezárás" : "☰ Menü"}
        </button>
        
        <Sidebar 
          isOpen={isSidebarOpen} 
          toggleSidebar={toggleMenus} 
          setActiveSystem={setActiveSystem}
        />
      </div>

      {/* GroupsSubMenu (ha kell az adott oldalon) */}
      {shouldShowGroupMenu && (
        <div
          className={`fixed ${isSidebarOpen ? "left-64" : "-translate-x-64"} top-0 h-full bg-gray-900 text-white w-64 transition-transform duration-300 ease-in-out z-40`}
        >
          <GroupsSubMenu 
            system_id={activeSystem ?? 2} 
            toggleSidebar={toggleMenus} 
            isSidebarOpen={isSidebarOpen} 
          />
        </div>
      )}

      {/* Fő tartalom, amely igazodik a Sidebar-hoz és GroupsSubMenu-hoz */}
      <div className={`flex-1 p-6 bg-gray-100 overflow-auto transition-all duration-300 ${
        isSidebarOpen ? (shouldShowGroupMenu ? "ml-128" : "ml-64") : "ml-0"
      }`}>
        <Outlet />
      </div>
    </div>
  );
};

export default Layout;
