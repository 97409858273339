import React, { useState, useEffect } from "react";
import { getList } from "../../../api/getSimulationData";
import { ListElement } from "../../../models/simulation/CommonTypes";
import { Systems } from "../../../enums/systems";
import { currencyFormatter } from "../../../utils/currencyFormatter";

export default function ProtokolTopList() {
  const [listElements, setListElements] = useState<ListElement[]>([]);
  const [selectedSystem, setSelectedSystem] = useState<number>(Systems.HPM);
  const [spinner, setSpinner] = useState(false);
  const [date, setDate] = React.useState<Date>(new Date());

  useEffect(() => {
    setSpinner(true);
    getList(selectedSystem, date)
      .then((res) => {
        setListElements(res);
        setSpinner(false);
      })
      .catch((err) => {
        console.error(err);
        setSpinner(false);
      });
  }, [selectedSystem]);

  return (
    <div className="m-5 p-5 bg-gray-100 rounded-lg shadow-lg">
      <h2 className="text-xl font-semibold text-gray-800 mb-4">Toplista</h2>
      {spinner ? (
        <p className="text-center text-2xl animate-pulse">
          Kérlek várj még az adatok betöltődnek.
        </p>
      ) : (
        <>
          {/* System Selector */}
          <div className="mb-5 flex gap-4">
            <label className="flex items-center space-x-2">
              <input
                type="radio"
                name="system"
                value={Systems.HPM}
                checked={selectedSystem === Systems.HPM}
                onChange={() => setSelectedSystem(Systems.HPM)}
                className="form-radio text-blue-500"
              />
              <span className="text-gray-700">HUMAN D.C.</span>
            </label>
            <label className="flex items-center space-x-2">
              <input
                type="radio"
                name="system"
                value={Systems.ALT}
                checked={selectedSystem === Systems.ALT}
                onChange={() => setSelectedSystem(Systems.ALT)}
                className="form-radio text-blue-500"
              />
              <span className="text-gray-700">BRAVE</span>
            </label>
          </div>
          <div className="flex flex-row space-x-4 justify-center items-center mb-5">
            <label>Kérlek válaszd ki a dátumot, mely dátumig szeretnéd lejátszani a kereskedést: </label>
            <input onChange={(e) => setDate(new Date(e.target.value))} type="date"></input>
            <button className="rounded px-2 py-2 bg-dark-blue text-cream opacity-80 hover:opacity-100">Listázom {date.toISOString().split('T')[0]} -ig.</button>
          </div>

          {/* Top List */}
          <div className="bg-white rounded-lg shadow-md p-4">
            <table className="w-full table-auto">
              <thead>
                <tr className="bg-gray-200 text-gray-700 text-left">
                  <th className="px-4 py-2">Dátum</th>
                  <th className="px-4 py-2">Név</th>

                  <th className="px-4 py-2">Befizetett (EUR)</th>
                  <th className="px-4 py-2">Jelenlegi portfolio állás (USD)</th>

                  <th className="px-4 py-2">Kifizetve (EUR)</th>
                  <th className="px-4 py-2">Kifizetés dátuma</th>

                  <th className="px-4 py-2">Százalék</th>
                </tr>
              </thead>
              <tbody>
                {listElements.map((item, index) => (
                  <tr key={index} className="border-b last:border-b-0">
                    <td className="px-4 py-2">{new Date(item.date).toLocaleDateString()}</td>
                    <td className="px-4 py-2 font-semibold">{item.name}</td>

                    <td className="px-4 py-2 font-semibold">{currencyFormatter(item.invested_amount_eur, "EUR")}</td>
                    <td className="px-4 py-2 font-semibold">{currencyFormatter(parseFloat(item.totalValue.toString()), "USD")}</td>

                    <td className="px-4 py-2 font-semibold">{currencyFormatter(item.payout_amount, "EUR")}</td>
                    <td className="px-4 py-2">{item.payout_date ? new Date(item.payout_date).toLocaleDateString() : "Nincs"}</td>

                    <td
                      className={`px-4 py-2 font-semibold ${parseFloat(
                        item.rate.toString()
                      ) > 0 ? "text-green-600" : "text-red-600"}`}
                    >
                      {item.rate.toString()}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </>
      )}
    </div>
  );
}